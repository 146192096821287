<template>
    <div class="relative">
        <input v-model.number="internalValue" type="number" inputmode="numeric" autocomplete="off" v-bind="{
            id,
            required: required || undefined,
            step,
        }" @blur="onInput" />

        <button type="button" class="minusbutton button --icon" :class="value < 0 ? '' : '--white'" @click="toggleMinus">
            <icon class="mdi-minus" />
        </button>
    </div>
</template>

<script>
export default {
    name: 'TemperatureInput',
    props: {
        value: Number,
        id: String,
        step: {
            type: Number,
            default: 0.01,
        },
        required: Boolean,
    },
    data() {
        return {
            internalValue: this.value,
        };
    },
    methods: {
        toggleMinus() {
            if (typeof this.internalValue === 'number' && this.internalValue !== 0) {
                this.$emit('input', -this.internalValue);
            } else {
                this.$emit('input', -1);
            }
        },

        onInput() {
            setTimeout(() => {
                const numericValue = this.internalValue;
                if (Number.isNaN(numericValue)) {
                    this.$emit('input', undefined);
                } else {
                    this.$emit('input', numericValue);
                }
            }, 100);
        }
    },
    watch: {
        value(newValue) {
            this.internalValue = newValue;
        },
    },
}
</script>

<style>
.relative {
    position: relative;
}

.minusbutton {
    position: absolute;
    top: 0;
    right: 0;
}
</style>