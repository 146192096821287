<template>
    <results>
        <div class="list__header" v-if="$slots.header">
            <slot name="header" />
        </div>

        <list>
            <template v-for="(group, index) in items">
                <item v-if="items.length > 1" class="list__header --bg-highlight --color-ondark-primary" :key="'grouptitle'+index">
                    {{ group.group }}
                </item>

                <ListItem v-for="item in group.items" :item="item" :key="item.id" :type="type" :selected="selected_ids.includes(item.id)" @click="click($event, item)" />
            </template>

            <item v-if="!items.length || !items[0].items.length">
                <slot name="empty" />
                <template v-if="!$slots.empty">
                    <h2>{{ $i18n.t('general.no_results_title') }}</h2>
                    <h4>{{ custom('noresults.empty') || $i18n.t('general.no_results_text') }}</h4>
                </template>
            </item>
        </list>
    </results>
</template>

<script>
import ListItem from '@/components/ListItemLoader.vue';

export default {
    name: 'ResultsList',
    components: { ListItem },
    props: {
        data: Object,
        group: Object,
        settings: Object,
        selected_ids: {
            type: Array,
            default: () => [],
        },
        type: String,
    },
    data() {
        return {
            items: [],
            group_by: [],
        };
    },
    watch: {
        group: {
            handler(value) { this.group_by = value; },
            immediate: true,
        },
        'data.group': {
            handler(value) { this.group_by = value; },
            immediate: true,
        },
        'data.items': {
            handler: 'change_type',
            immediate: true,
        },
    },
    methods: {
        change_type() {
            this.items = this.create_groups(this.data.items);
        },

        create_groups(items) {
            const groups = [];
            const created_groups = [];
            function add_to_group(filter, item, props) {
                const index = created_groups.indexOf(filter);

                if (index === -1) {
                    const grouptitle = props.group_by.title ? props.grouptitle(filter) : filter;
                    created_groups.push(filter);
                    groups.push({ group: grouptitle, items: [item] });
                } else {
                    groups[index].items.push(item);
                }
            }

            if (this.group_by && this.group_by.value) {
                items.forEach((item) => {
                    const filter = item[this.group_by.value];
                    add_to_group(filter, item, this);
                });
            } else if (this.group_by && typeof this.group_by.function === 'function') {
                items.forEach((item) => {
                    const filter = this.group_by.function(item);
                    add_to_group(filter, item, this);
                });
            } else {
                groups.push({ group: 'All', items });
            }
            return groups;
        },

        custom(prop) {
            const parts = prop.split('.');
            try {
                const val = this.data.custom_msgs[parts[0]][parts[1]];
                return val;
            } catch (err) {
                return false;
            }
        },

        grouptitle(val) {
            const { title } = this.group_by;
            return title.replace('%name%', val);
        },

        click(e, item) {
            // OPTIMIZE shift-select
            // OPTIMIZE fixed element onderin balk met X-geselecteerd. En ongedaan maken.
            let selection = this.selected_ids || [];
            if (this.data.multi_select !== false && this.settings.multi_select && (e.ctrlKey || e.shiftKey || e.metaKey)) {
                if (selection.includes(item.id)) {
                    selection = selection.filter((sel) => sel !== item.id);
                } else {
                    selection.push(item.id);
                }
            } else {
                selection = [item.id];
            }
            this.$emit('selection_changed', selection);
        },
    },
};
</script>
<!-- OPTIMIZE loading state -->
