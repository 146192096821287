import Vue from 'vue';
import { createI18n } from 'vue-i18n-bridge'
import VueI18n from 'vue-i18n';

import nl from './locales/nl.json';
import de from './locales/de.json';
import en from './locales/en.json';

const messages = {
    nl,
    de,
    en,
}

Vue.use(VueI18n, { bridge: true });

const i18n = createI18n(
    {
        legacy: false,
        locale: import.meta.env.VITE_I18N_LOCALE || 'nl',
        silentTranslationWarn: true,
        missingWarn: false,
        warnHtmlMessage: false,
        messages,
    },
    VueI18n
);

Vue.use(i18n);

export default i18n;
