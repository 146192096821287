import i18n from '@/i18n';
import store from '@/store';
import { captureException } from '@sentry/vue';

const apiErrors = {
    methods: {
        apiError(err, allowedCodes) {
            let errordata = err.data || {};
            let statuscode = err.status || 0;
            if (err.data && typeof err.data === 'string') {
                errordata = JSON.parse(errordata);
            }
            if (errordata.error_code) {
                statuscode = errordata.error_code;
            }
            console.group(`Api Error: ${errordata.message || ''}`);
            if (allowedCodes && allowedCodes.includes(statuscode)) {
                console.log('Statuscode is allowed');
            } else {
                this.processError(statuscode, errordata, err);
            }
            console.log(err);
            console.trace();
            console.groupEnd();
        },

        processError(code, data = {}, err) {
            const errorObj = store.getters['Errors/errorByCode'](code);
            if (errorObj) {
                const msg = errorObj.useResponseMsg ? (data.message || 'Error') : (errorObj.message || 'Error');
                if (errorObj.fatal === false) {
                    store.commit('Toaster/add', { msg, classes: '--error' });
                } else if (errorObj.fatal === true) {
                    store.state.app.loading_fullscreen.show = true;
                    store.state.app.loading_fullscreen.title = errorObj.title || i18n.t('errors.title_general');
                    store.state.app.loading_fullscreen.message = msg;
                }

                if (typeof errorObj.callback === 'function') {
                    errorObj.callback();
                }
            } else {
                let error = new Error('ErrorObj not found');
                let errordata = {
                    extra: {
                        section: 'process error',
                        code, data, err
                    },
                };
                if (typeof err?.message === 'string' && err.message.toLowerCase().includes('timeout')) {
                    error = new Error('Network timeout');
                    errordata.extra.section = 'timeout';
                }
                if (import.meta.env.DEV) {
                    console.log('sentry exception', error, errordata);
                } else {
                    captureException(error, errordata);
                }
            }
        },
    },
};

export default apiErrors;
