<template>
    <div v-if="data.initials || data.done_date || showRemark" class="info__list not-item">
        <div class="add-padding_side" v-if="showRemark">
            <div class="--size6 --color-secondary">{{ $i18n.t('tasks.remark') }}</div>
            <div v-html="remark" />
            <div class="--color-error --size6">
                <span v-if="data.not_executed">
                    <icon class="mdi-alert-box" />
                    {{ $i18n.t('tasks.not_executed_task') }}
                </span>
                <span v-if="data.defect">
                    <icon class="mdi-image-broken-variant" />
                    {{ $i18n.t('tasks.is_defect') }}
                </span>
            </div>
        </div>

        <div class="add-padding_side">
            <div class="--size6 --color-secondary">{{ $i18n.t('tasks.done_by') }}</div>
            <div>
                <span v-if="data.initials">{{ data.initials }}&nbsp;</span>
                <span
                    v-if="type === 'temperature-preparation' && data.regeneration_end"
                    class="--color-secondary --size6"
                >({{ convert_date(data.regeneration_time, '', 'prettytime') }} - {{ convert_date(data.regeneration_end, '', 'prettytime') }})</span>
                <span v-if="type === 'temperature-cool' && data.initials_cool">- {{ data.initials_cool }}</span>
                <span v-if="doneDate" class="--color-secondary --size6">{{ doneDate }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { convert_date } from '@/assets/js/helpers';

export default {
    name: 'TaskCredits',
    props: {
        data: Object,
        type: String,
    },
    computed: {
        doneDate() {
            if (this.data.checked_timestamp && this.data.checked_timestamp > 0) {
                return convert_date(this.data.checked_timestamp, '', 'taskcredits-long', undefined, this.$i18n.locale);
            }
            return false;
        },

        showRemark() {
            const message = this.data.remark;
            if (message && typeof message === 'string' && message.startsWith('t:')) {
                return true;
            }
            if (!['temperature-storage', 'temperature-dishwasher'].includes(this.type) && this.data.remark) {
                if (this.type.startsWith('temperature-')) {
                    return this.data.checked;
                }
                return true;
            }
            return false;
        },

        remark() {
            const message = this.data.remark;
            if (message && typeof message === 'string' && message.startsWith('t:')) {
                if (message === 't:auto_checked_by_system') {
                    return this.$i18n.t('tasks.done_by_system_automatically');
                }
                return '';
            }
            return this.$options.filters.nl2br(message || '')
        },
    },
    methods: {
        convert_date,
    },
};
</script>
