<template>
    <page class="page__tasks layout__split">
        <div class="layout__split_left">
            <div v-if="selected_department?.parent?.name" class="card --color-muted --size6 mb-r">
                {{  $i18n.t('departments.part-of') }}
                <span class="--color-primary">
                    {{ selected_department.parent.name }}
                </span>
            </div>
            <div class="taken-day">
                <div class="taken-header --color-muted" v-if="selected_department && this_day">
                    <div class="taken-date">
                        <h6 class="ellipsis --color-secondary">{{ date.toLocaleDateString(this.$i18n.locale, {
                            weekday:
                                'long'
                        }) }}</h6>
                        <h3 class="ellipsis">{{ date.toLocaleDateString(this.$i18n.locale, {
                            day: 'numeric', month: 'long'
                        }) }}</h3>
                    </div>
                    <div
                        v-if="!loading_tasks && !done && !closed && this_day <= selected_department.next_due_timestamp && !dayInFuture">
                        <button type="button" @click="complete_day" class="button --bg-correct">
                            {{ $i18n.t('actions.mark_as_done') }}
                            <icon class="mdi-check"></icon>
                        </button>
                    </div>
                </div>
            </div>

            <notice v-if="selected_department.next_due_timestamp && this_day > selected_department.next_due_timestamp"
                class="--bg-info">
                <h2>{{ $i18n.t('tasks.not_all_days_completed') }}</h2>
                <h4>{{ $i18n.t('tasks.not_all_days_completed_text') }}</h4>
                <br />
                <router-link to="/tasks" class="button --no-icon">{{ $i18n.t('tasks.item_plural') }}</router-link>
            </notice>

            <div v-if="closed" class="taken-list">
                <list>
                    <item>
                        <Empty :title="$i18n.t('tasks.closed_title')" :text="$i18n.t('tasks.closed_text')"></Empty>
                    </item>
                </list>
            </div>
            <div v-else class="taken-list"
                :disabled="selected_department.next_due_timestamp && this_day > selected_department.next_due_timestamp">
                <TaskGroup v-for="(group, name) in taskgroups" :key="name + '_' + computed_count" :tasks="group"
                    :name="name" :selected_id="id" :no_periodical="!taskgroups.periodical.length" :done="done" @open="open"
                    @changed="changed_tasks"></TaskGroup>
            </div>
        </div>

        <content>
            <router-view v-if="id" :data="selected_task" :parent_done="done" @close="deselect"
                @change="changed_tasks"></router-view>
        </content>

        <Initials v-if="initials.show" :resolve="initials.resolve" :reject="initials.reject" />
        <QuickReport v-if="quickReport.show" :resolve="quickReport.resolve" :reject="quickReport.reject"
            :taskgroups="taskgroups" />
    </page>
</template>

<script>
import { api } from '@/api';
import Empty from '@/components/Empty.vue';
import TaskGroup from '@/views/tasks/TaskGroup.vue';

export default {
    name: 'Tasks',
    components: {
        Empty,
        Initials: () => import('@/components/Initials.vue'),
        QuickReport: () => import('@/components/QuickReport.vue'),
        TaskGroup,
    },
    props: {
        id: Number,
        timestamp: Number,
    },
    data() {
        return {
            done: false,
            closed: false,
            data: {},
            tasks: [],
            loading_tasks: false,
            results_timestamp: null,
            initials: {
                show: false,
                value: '',
                resolve: null,
                reject: null,
            },
            quickReport: {
                show: false,
                resolve: null,
                reject: null,
            },
            computed_count: 0, // workaround for statusupdates
        };
    },
    computed: {
        dayInFuture() {
            return this.this_day > (Date.now() / 1000);
        },

        date() {
            if (this.results_timestamp) {
                return new Date(this.results_timestamp * 1000);
            }
            return new Date(this.timestamp * 1000);
        },

        this_day() {
            return this.timestamp || this.results_timestamp;
        },

        taskgroups() {
            const data = {
                today: [],
                today_after_use: [],
                periodical: [],
                done: [],
                number: this.computed_count,
            };

            for (let i = 0, len = this.tasks.length; i < len; i += 1) {
                const task = this.tasks[i];

                if (task.checked) {
                    data.done.push(task);
                } else if (task.task_interval === 1234) {
                    data.today_after_use.push(task);
                } else if (task.group === 'daily' || task.group === 'today') {
                    data.today.push(task);
                } else {
                    data.periodical.push(task);
                }
            }

            if (this.done && !data.today.length) {
                delete data.today;
            }

            return data;
        },

        selected_task() {
            if (this.id) {
                return this.tasks.find((it) => it.id === this.id);
            }
            return {};
        },

        selected_department() {
            return this.$store.state.selected_department;
        },
    },
    methods: {
        async complete_day() {
            const has_unfinished_tasks_today = this.taskgroups.today.length;
            const has_unfinished_tasks_optional = has_unfinished_tasks_today || this.taskgroups.periodical.length > 0 || this.taskgroups.today_after_use.length > 0;
            const is_housekeeping = this.selected_department.type === 'housekeeping';
            const allowed_to_check_all_daily = this.selected_department.check_day_tasks_in_one_go;

            if (is_housekeeping && has_unfinished_tasks_optional && allowed_to_check_all_daily) {
                const qr = await this.quick_report();
                if (qr.success) {
                    return this.report_day(qr.initials);
                }
            } else {
                if (has_unfinished_tasks_today) {
                    this.$store.commit('Toaster/add', { msg: this.$i18n.t('tasks.not_all_completed'), classes: '--error' });
                    return false;
                }
                this.report_day();
            }
        },

        async quick_report() {
            const promise = new Promise((resolve, reject) => {
                this.quickReport.resolve = resolve;
                this.quickReport.reject = reject;
                this.quickReport.show = true;
            });

            const response = await promise.catch(() => { });
            // if response is false, the user cancelled the quick report
            if (!response) {
                this.quickReport.resolve = null;
                this.quickReport.reject = null;
                this.quickReport.show = false;
                return false;
            }

            this.quickReport.resolve = null;
            this.quickReport.reject = null;
            this.quickReport.show = false;
            const { initials } = await this.changed_tasks({ ids: response, action: 'check' });
            return {
                success: true,
                initials,
            }
        },

        async get_initials(input_initials) {
            if (input_initials) {
                return input_initials;
            }
            if (!this.$store.state.user.data.multi_user) {
                return false;
            }
            const promise = new Promise((resolve, reject) => {
                this.initials.resolve = resolve;
                this.initials.reject = reject;
                this.initials.show = true;
            });

            const initials = await promise;

            // hide
            this.initials.resolve = null;
            this.initials.reject = null;
            this.initials.show = false;

            if (!initials) {
                this.$store.commit('Toaster/add', { msg: this.$i18n.t('tasks.enter_initials'), classes: '--error' });
                return undefined;
            }
            return initials;
        },

        open(payload) {
            let path = `/tasks/${this.results_timestamp}/${payload.id}/`;
            switch (payload.type) {
                case 'Cleaning':
                    path += 'Cleaning';
                    break;
                case 'Temperature-cool':
                    path += 'Cool';
                    break;
                case 'Temperature-fryer':
                    path += 'Fryer';
                    break;
                case 'Temperature-preparation':
                    path += 'Preparation';
                    break;
                case 'Temperature-regeneration':
                    path += 'Regeneration';
                    break;
                case 'Temperature-receive':
                    path += 'Receive';
                    break;
                case 'Temperature-storage':
                    path += 'Storage';
                    break;
                case 'Temperature-dishwasher':
                    path += 'Dishwasher';
                    break;
                case 'Temperature-portioning':
                    path += 'Portioning';
                    break;
                case 'Temperature-twohours':
                    path += 'Twohours';
                    break;
                case 'Temperature-custom':
                    path += 'Custom';
                    break;
                default:
                    return;
            }
            this.$router.push({ path })
                .catch((err) => {
                    console.log(err);
                });
        },

        get_data() {
            if (this.selected_department?.id) {
                this.loading_tasks = true;
                api.get({
                    name: 'get_tasks',
                    endpoint: 'tasks/',
                    loading: true,
                    data: { timestamp: this.timestamp, department: this.selected_department.id },
                }).then((r) => {
                    if (r.data && r.data.results) {
                        this.closed = false;
                        this.done = r.data.results.done;
                        this.data = r.data.results.department;
                        this.tasks = r.data.results.tasks;
                        this.results_timestamp = r.data.results.timestamp;
                    }
                }).catch((err) => {
                    if (err.status === 404) {
                        this.closed = true;
                        this.results_timestamp = this.timestamp;
                        this.tasks = [];
                    } else {
                        this.apiError(err);
                    }
                }).finally(() => {
                    this.loading_tasks = false;
                });
            }
        },

        deselect() {
            this.$router.push({ path: `/tasks/${this.results_timestamp}` }).catch(() => { });
        },

        async changed_tasks(payload) {
            let initial_mode = 'undetermined';
            if (this.$store.state.user.data.multi_user) {
                if (payload.ids.length === 1) {
                    const single_task = this.tasks.find((it) => it.id === payload.ids[0]);

                    if (!single_task.initials) {
                        initial_mode = 'default';
                    } else if (single_task.type === 'Temperature-cool' && single_task.cool_time && !single_task.initials_cool) {
                        initial_mode = 'cool';
                    } else if (single_task.type === 'Temperature-twohours' && single_task.cool_time && !single_task.initials_cool) {
                        initial_mode = 'twohours';
                    }
                } else {
                    initial_mode = 'default';
                }
            }

            let initials = false;
            if (initial_mode !== 'undetermined') {
                initials = await this.get_initials(payload?.initials).catch(() => {
                    const task = this.tasks.find((it) => it.id === payload.ids[0]);
                    task.checked = false;

                    this.computed_count += 1;
                });
                if (initials !== false && !initials) {
                    return false;
                }
            }

            const changed_tasks = [];
            for (let i = 0, len = this.tasks.length; i < len; i += 1) {
                const task = this.tasks[i];
                if (payload.ids.includes(task.id)) {
                    if ((payload.action && payload.action === 'check') || payload.checked) {
                        task.checked = true;
                    } else if (payload.checked === false) {
                        task.checked = false;
                        task.checked_timestamp = null;
                        task.initials = '';
                    }
                    if (task.checked && !task.checked_timestamp) {
                        task.checked_timestamp = Math.floor(Date.now() / 1000);
                    }
                    if (initials) {
                        if (initial_mode === 'default' && !task.initials) {
                            task.initials = initials;
                        } else if (initial_mode === 'cool' && !task.initials_cool) {
                            task.initials_cool = initials;
                        } else if (initial_mode === 'twohours' && !task.initials_cool) {
                            task.initials_cool = initials;
                        }
                    }
                    changed_tasks.push(task);
                }
            }
            if (changed_tasks.length) {
                this.computed_count += 1;
            }

            await this.report(changed_tasks);
            return {
                initials,
            }
        },

        async report(data) {
            return api.post({
                name: `update_tasks_${this.computed_count}`,
                endpoint: 'actions/',
                data,
                loading: true,
            }).catch((err) => {
                this.apiError(err);
            });
        },

        async report_day(input_initials) {
            const initials = await this.get_initials(input_initials);
            if (initials !== false && !initials) {
                return false;
            }

            const data = {
                department: this.selected_department.id,
                day: this.results_timestamp,
            };
            if (initials) {
                data.done_by_initials = initials;
            }

            api.patch({
                name: 'update_day',
                endpoint: 'tasklists/',
                data,
                loading: true,
            }).then((r) => {
                if (r.data && r.data.status === 'success') {
                    this.selected_department.last_marked_done_timestamp = this.results_timestamp;
                    this.selected_department.next_due_timestamp = r.data.results.next_due_timestamp;
                    if (r.data.results.next_due_timestamp) {
                        if (this.this_day === r.data.results.next_due_timestamp) {
                            this.get_data();
                        } else {
                            this.$router.push({ path: `/tasks/${r.data.results.next_due_timestamp}/` }).catch(() => { });
                        }
                    }
                } else {
                    throw new Error('Something went wrong while posting this');
                }
            }).catch((err) => {
                this.$store.commit('Toaster/add', { msg: this.$i18n.t('tasks.error_closing_day'), classes: '--error' });
                console.log(err);
            });
        },
    },
    watch: {
        timestamp: {
            handler: 'get_data',
            immediate: true,
        },
        '$store.state.selected_department': 'get_data',
    },
};
</script>
<style>
.item__left h2 {
    font-weight: 500;
}
</style>
