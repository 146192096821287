<template>
    <modal>
        <content v-on-clickaway="close" v-if="data">
            <Nav :header="true" :brand="false" :fixed="true">
                <template slot="left">
                    <li>
                        <div>{{ $i18n.t('temperatures.receive_order', { supplier: data.supplier }) }}</div>
                    </li>
                </template>
                <template slot="nav_right">
                    <li>
                        <button type="button" class="button --neutral --icon" @click="close">
                            <icon class="mdi-undo-variant"></icon>
                        </button>
                    </li>
                </template>
            </Nav>

            <TaskRemarks v-bind="{ done: $parent.done, data, type: 'temperature-receive' }" @change="onRemark"
                @show="show_remarks = true" @hide="show_remarks = false" />

            <div v-if="!show_remarks && !data.checked">
                <notice class="--bg-info" v-if="remaining_products > 0 || remaining_categories > 0">
                    <div>
                        <span v-if="data.measurements.length === 0 && data.min_products_amount">
                            {{ $tc('temperatures.products_minimum', data.min_products_amount) }}
                            <span v-if="remaining_categories > 0">{{ $tc('temperatures.products_minimum_categories',
                                remaining_categories) }}</span>
                        </span>
                        <span v-else-if="data.measurements.length > 0">
                            <span v-if="remaining_products > 0">{{ $tc('temperatures.products_remaining',
                                remaining_products) }}&nbsp;</span>
                            <span v-else-if="remaining_categories > 0">{{ $i18n.t('temperatures.products_none_remaining')
                            }}&nbsp;</span>
                            <span v-if="remaining_categories > 0">{{ $tc('temperatures.products_minimum_categories',
                                remaining_categories) }}</span>
                        </span>
                    </div>
                </notice>

                <div class="add-padding_side">
                    <h5 class="--color-highlight">
                        <br />
                        {{ $i18n.t('temperatures.new_measurement') }}
                        <template v-if="measurement.category">: {{ $i18n.t('food_categories.' + measurement.category)
                        }}</template>
                    </h5>
                </div>
                <ul class="choose_category add-padding_side" v-if="!measurement.category">
                    <li v-for="(cat, index) in categories" :key="index">
                        <button class="button --neutral" :class="{ '--no-icon': !cat.icon }" type="button"
                            @click="category_select(cat)">
                            {{ $i18n.t('food_categories.' + cat.id) }}
                            <icon v-if="cat.icon" :class="'mdi-' + cat.icon"></icon>
                        </button>
                    </li>
                </ul>

                <div v-if="measurement.category">
                    <form class="add-padding_side" @submit.prevent="$nextTick(() => { save_measurement() })"
                        @keydown.enter="enterClick">
                        <fieldset v-if="department.reception_productname_required">
                            <label for="temp_productname">{{ $i18n.t('temperatures.fields.product_name') }}</label>
                            <input type="text" id="temp_productname" v-model="measurement.name" required autofocus />
                        </fieldset>

                        <fieldset v-if="!selected_category.temp_not_required">
                            <label for="temp_producttemp">{{ $i18n.t('temperatures.fields.temperature') }}</label>
                            <TemperatureInput id="temp_producttemp" v-model="measurement.temperature" :required="true"
                                @input="check_temp" />
                        </fieldset>

                        <div v-if="selected_category.id === 'freezer'">
                            <small class="--color-secondary">
                                <span class="tag --bg-info --color-primary">
                                    <span class="mdi mdi-information-outline"></span> info
                                </span>
                                <br />
                                {{ $i18n.t('temperatures.tips.freezer_measurement') }}
                            </small>
                            <br />
                            <br />
                        </div>

                        <fieldset class="checkbox--double-line">
                            <input type="checkbox" id="temp_expired" v-model="inverted_expired"
                                @input="measurement.expired = !!inverted_expired" />
                            <label for="temp_expired">
                                {{ $i18n.t('temperatures.fields.freshness_title') }}?
                                <div class="--size6 --color-muted">{{ $i18n.t('temperatures.fields.freshness_subtitle') }}
                                </div>
                            </label>
                        </fieldset>

                        <fieldset class="checkbox--double-line">
                            <input type="checkbox" id="temp_hygiene_ok" v-model="measurement.hygiene_ok" />
                            <label for="temp_hygiene_ok">
                                {{ $i18n.t('temperatures.fields.hygiene_title') }}?
                                <div class="--size6 --color-muted">{{ $i18n.t('temperatures.fields.hygiene_subtitle') }}
                                </div>
                            </label>
                        </fieldset>

                        <fieldset class="checkbox--double-line">
                            <input type="checkbox" id="temp_visual" v-model="measurement.visual_check" />
                            <label for="temp_visual">
                                {{ $i18n.t('temperatures.fields.packaging_title') }}?
                                <div class="--size6 --color-muted">{{ $i18n.t('temperatures.fields.packaging_subtitle') }}
                                </div>
                            </label>
                        </fieldset>

                        <fieldset class="checkbox--double-line">
                            <input type="checkbox" id="temp_quantity_ok" v-model="measurement.quantity_ok" />
                            <label for="temp_quantity_ok">
                                {{ $i18n.t('temperatures.fields.quantity_title') }}?
                                <div class="--size6 --color-muted">{{ $i18n.t('temperatures.fields.quantity_subtitle') }}
                                </div>
                            </label>
                        </fieldset>

                        <notice
                            v-if="measurement.expired || !measurement.visual_check || !measurement.hygiene_ok || !measurement.quantity_ok"
                            class="--bg-warning">
                            <div>{{ $store.state.selected_department.reception_expiration_date_protocol }}</div>
                        </notice>

                        <notice v-if="measurement.temperature_ok === 'warning'" class="--bg-info">
                            <div>{{ $i18n.t('temperatures.pastry_tempwarning') }}</div>
                        </notice>
                        <notice v-else-if="!measurement.temperature_ok" class="--bg-warning">
                            <div>{{ $store.state.selected_department.reception_temperature_protocol }}</div>
                        </notice>

                        <fieldset
                            v-if="(measurement.temperature_ok === false || measurement.expired || !measurement.visual_check || !measurement.hygiene_ok || !measurement.quantity_ok)">
                            <label for="remark">{{ $i18n.t('tasks.remark') }}</label>
                            <textarea v-model="data.remark" required></textarea>
                        </fieldset>

                        <button class="button" type="button" @click="delayedSubmit">
                            {{ $i18n.t('actions.save') }}
                            <icon class="mdi-check"></icon>
                        </button>

                        <button class="button" type="submit" ref="submitbutton" hidden>real {{ $i18n.t('actions.save')
                        }}</button>
                        <div v-if="measurement.category === 'shellfish'" class="--color-secondary savenotice"
                            v-html="'&mdash; ' + $i18n.t('food_categories.shellfish_notice', { temp: selected_category.max_temperature })">
                        </div>
                        <br />
                    </form>

                    <div class="add-padding_side" v-if="data.notes">
                        <br />
                        <div class="--size6 --color-secondary">{{ $i18n.t('tasks.instructions') }}</div>
                        <div v-html="data.notes"></div>
                    </div>
                </div>
            </div>

            <div v-if="!measurement.category && (data.measurements.length > 0 || data.checked)"
                class="info__list list__measurements">
                <div class="add-padding_side not-item" v-if="data.measurements.length > 0">
                    <h5 class="--color-highlight">
                        <br />
                        {{ $i18n.t('temperatures.measurements') }}
                    </h5>
                </div>

                <ReceiveMeasurements v-if="data.measurements.length > 0" :measurements="data.measurements" :data="data">
                </ReceiveMeasurements>

                <TaskCredits v-bind="{ data, type: 'temperature-receive' }" />
            </div>
        </content>
    </modal>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import Nav from '@/components/Nav.vue';
import ReceiveMeasurements from '@/views/tasks/measurements/Temperature-receive.vue';
import TaskRemarks from '@/views/tasks/modals/TaskRemarks.vue';
import TaskCredits from '@/views/tasks/modals/TaskCredits.vue';
import TemperatureInput from '@/components/forms/TemperatureInput.vue';

export default {
    name: 'TemperatureReceiveTaskSpecs',
    components: {
        ReceiveMeasurements,
        Nav,
        TaskRemarks,
        TaskCredits,
        TemperatureInput,
    },
    props: {
        data: Object,
        parent_done: Boolean,
    },
    data() {
        return {
            measurement: {},
            selected_category: null,
            show_remarks: false,
            inverted_expired: false,
        };
    },
    methods: {
        close(payload) {
            if (!this.$store.state.app.show_initials && !(payload && payload.target && payload.target.nodeName.toLowerCase() === 'button')) {
                this.$emit('close');
            }
        },

        category_select(cat) {
            this.selected_category = cat;
            this.measurement.category = cat.id;
        },

        check_temp() {
            if (typeof this.measurement.temperature === 'string' && this.measurement.temperature.includes(',')) {
                this.measurement.temperature = +this.measurement.temperature.replace(',', '.');
            }
            let temp_ok = false;
            if ('max_temperature' in this.selected_category) {
                temp_ok = parseFloat(this.measurement.temperature) <= parseFloat(this.selected_category.max_temperature);
            } else if ('min_temperature' in this.selected_category) {
                temp_ok = parseFloat(this.measurement.temperature) >= parseFloat(this.selected_category.min_temperature);
            }
            if (!temp_ok && this.selected_category.id === 'pastry' && parseFloat(this.measurement.temperature) <= 20) {
                this.measurement.temperature_ok = 'warning';
            } else {
                this.measurement.temperature_ok = temp_ok;
            }
        },

        measurement_data() {
            return {
                category: '',
                name: '',
                temperature: null,
                temperature_ok: true,
                visual_check: true,
                hygiene_ok: true,
                quantity_ok: true,
                expired: false,
            };
        },

        new_measurement() {
            this.measurement = this.measurement_data();
            this.inverted_expired = true;
        },

        save_measurement() {
            if (!this.parent_done) {
                this.data.measurements.push(JSON.parse(JSON.stringify(this.measurement)));
                this.new_measurement();

                if (this.remaining_products < 1 && this.remaining_categories < 1) {
                    this.data.checked = true;
                }
                if (this.data.checked) {
                    this.close();
                }
                this.$emit('change', { ids: [this.data.id] });
            }
        },
    },
    computed: {
        amount_categories() {
            const categories = this.data.measurements.map((it) => it.category);
            const count = [];
            for (let i = 0, len = categories.length; i < len; i += 1) {
                if (!count.includes(categories[i])) {
                    count.push(categories[i]);
                }
            }
            return count.length;
        },

        categories() {
            return this.$store.getters.categories;
        },

        remaining_products() {
            if (this.data.min_products_amount && this.data.min_products_amount > 0) {
                return this.data.min_products_amount - this.data.measurements.length;
            }
            return 0;
        },

        remaining_categories() {
            if (this.data.min_categories_amount && this.data.min_categories_amount > 0) {
                return this.data.min_categories_amount - this.amount_categories;
            }
            return 0;
        },

        department() {
            return this.$store.getters.department;
        },
    },
    mounted() {
        this.new_measurement();
    },
    directives: {
        onClickaway,
    },
};
</script>
