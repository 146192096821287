const tasks = {
    methods: {
        delayedSubmit() {
            document.activeElement.blur();
            setTimeout(() => {
                if (this.$refs && this.$refs.submitbutton) {
                    this.$refs.submitbutton.click();
                }
            }, 500);
        },

        enterClick(e) {
            if (e && e.target.nodeName.toLowerCase() !== 'textarea') {
                e.preventDefault();
                this.delayedSubmit();
            }
        },

        onRemark(payload) {
            if (payload.defect) {
                this.data.defect = true;
            }
            if (this.data.remark) {
                this.data.checked = true;
            }
            this.$emit('change', { ids: [this.data.id] });
            this.close();
        },
    },
};

export default tasks;
