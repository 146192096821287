<template>
    <modal>
        <content v-on-clickaway="close">
            <Nav :header="true" :brand="false" :fixed="true">
                <template slot="left">
                    <li><div>{{ $i18n.t('general.department_select') }}</div></li>
                </template>
                <template slot="nav_right">
                    <li><button type="button" class="button --neutral --icon" @click="close"><icon class="mdi-undo-variant" /></button></li>
                </template>
            </Nav>
            <resultslist :data="this.$store.state.data.departments" type="departments" :settings="{}" @selection_changed="select"></resultslist>
        </content>
    </modal>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway';
import Nav from '@/components/Nav.vue';
import Resultslist from '@/components/Resultslist.vue';

export default {
    name: 'PickDepartment',
    components: { Nav, Resultslist },
    methods: {
        select(e) {
            this.$store.dispatch('select_department', { id: e });
        },

        close() {
            this.$store.commit('pick_department', false);
        },
    },
    mounted() {
        this.$store.dispatch('get_departments', 'modal');
    },
    directives: {
        onClickaway,
    },
};
</script>
