import store from '@/store';
import router from '@/router';

export default {
    namespaced: true,
    state: {
        errors: {
            403: {
                name: 'Invalid API key',
                fatal: true,
                useResponseMsg: true,
                callback: () => {
                    setTimeout(() => {
                        router.push('/logout/').catch(() => {});
                        store.state.app.loading_fullscreen.show = false;
                        store.state.app.loading_fullscreen.title = '';
                        store.state.app.loading_fullscreen.message = '';
                    }, 4000);
                },
            },
            429: {
                name: 'Ratelimit reached',
                fatal: true,
                useResponseMsg: false,
            },
        },
    },
    getters: {
        errorByCode: (state) => (code) => {
            let obj = state.errors[code];
            if (typeof obj === 'number') {
                obj = state.errors[obj];
            }
            return obj;
        },
    },
};
