<template>
    <modal>
        <content v-on-clickaway="close" v-if="data">
            <Nav :header="true" :brand="false" :fixed="true">
                <template slot="left">
                    <li><div>{{ $i18n.t('tasks.task_info') }}</div></li>
                </template>
                <template slot="nav_right">
                    <li><button type="button" class="button --neutral --icon" @click="close"><icon class="mdi-undo-variant"></icon></button></li>
                </template>
            </Nav>

            <TaskRemarks
                v-bind="{ done: $parent.done, data, type: 'cleaning' }"
                @change="onRemark"
                @show="show_remarks = true"
                @hide="show_remarks = false"
            />

            <div class="info__list" v-if="!show_remarks">
                <div class="add-padding_side">
                    <div class="--size6 --color-secondary">{{ $i18n.t('tasks.item_single') }}</div>
                    <div>{{ data.object.name }}: {{ data.name }}</div>
                </div>
                <div class="add-padding_side" v-if="data.material_color_hex">
                    <div class="--size6 --color-secondary">{{ $i18n.t('tasks.material_color') }}</div>
                    <span class="tag --large --shadow" :style="'background-color: #'+ data.material_color_hex"></span>
                </div>

                <div class="add-padding_side" v-if="data.notes">
                    <div class="--size6 --color-secondary">{{ $i18n.t('tasks.instructions') }}</div>
                    <div v-html="data.notes"></div>
                </div>

                <div v-for="(prod, index) in products" :key="'prod_details_' + index" v-show="prod.name">
                    <div class="io-product add-padding_side" v-if="prod.name">
                        <div class="io-product_name">
                            <div class="--size6 --color-secondary">{{ $i18n.t('products.item_single') }}</div>
                            <div><span v-html="prod.name"></span> {{ prod.content || '' }}</div><br />
                            <img src="/img/etol_e.svg" v-if="prod.etolproduct" class="etol_product" />
                        </div>
                        <div v-if="prod.image1" class="io-product_img" :style="'background-image: url(\''+prod.image1+'\')'"></div>
                    </div>
                    <div class="add-padding_side" v-if="prod.instructions">
                        <div class="--size6 --color-secondary">{{ $i18n.t('tasks.instructions_supplier') }}</div>
                        <div v-html="prod.instructions"></div>
                    </div>
                    <div class="io-docs add-padding_side --size6" v-if="prod && (prod.product_information_sheet || prod.product_safety_information_sheet)">
                        <div class="--size6 --color-secondary">{{ $i18n.t('tasks.documents') }}</div>
                        <a v-if="prod && prod.product_information_sheet" :href="prod.product_information_sheet" target="_blank">
                            {{ $i18n.t('products.sheet_productinfo') }}
                        </a><br />
                        <a v-if="prod && prod.safety_information_sheet" :href="prod.safety_information_sheet" target="_blank">
                            {{ $i18n.t('products.sheet_safetyinfo') }}
                        </a>
                    </div>
                </div>

                <TaskCredits v-bind="{ data, type: 'cleaning' }" />
            </div>
        </content>
    </modal>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import Nav from '@/components/Nav.vue';
import TaskRemarks from '@/views/tasks/modals/TaskRemarks.vue';
import TaskCredits from '@/views/tasks/modals/TaskCredits.vue';

export default {
    name: 'CleaningTaskSpecs',
    components: { Nav, TaskRemarks, TaskCredits },
    props: {
        data: Object,
    },
    data() {
        return {
            show_remarks: false,
        };
    },
    methods: {
        close() {
            if (!this.$store.state.app.show_initials) {
                this.$emit('close');
            }
        },
    },
    computed: {
        products() {
            if (this.data && (this.data.product || this.data.product2)) {
                return this.$store.getters.products.filter((it) => this.data.product === it.id || this.data.product2 === it.id);
            }
            return [];
        },
    },
    directives: {
        onClickaway,
    },
};
</script>
