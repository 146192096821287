<template>
    <VueTimepicker v-model="converted_value" :format="format" :minute-interval="step" :hide-clear-button="!clear"
        auto-scroll />
</template>

<script>
// OPTIMIZE create my own timepicker
import VueTimepicker from 'vue2-timepicker';

export default {
    name: 'Timepicker',
    components: { VueTimepicker },
    props: {
        format: {
            type: String,
            default: 'HH:mm',
        },
        default: Number,
        clear: Boolean,
        step: Number,
        value: [String, Number, Boolean],
    },
    computed: {
        converted_value: {
            get() {
                let conv = '';
                if (this.value) {
                    conv = this.value;
                } else if (this.default) {
                    conv = this.default;
                }
                return this.number_to_params(conv);
            },

            set(value) {
                this.$emit('input', this.params_to_number(value));
            },
        },
    },
    methods: {
        number_to_params(num) {
            const time = { HH: '00', mm: '00' };
            num = num.toString();
            const len = num.length;
            time.HH = (len > 2) ? num.substring(0, len - 2) : '00';
            time.HH = `00${time.HH}`.substr((time.HH.length + 2) - 2);
            time.mm = (len > 1) ? num.substring(len - 2) : `00${num}`.substr((num.length + 2) - 2);
            return time;
        },

        params_to_number(par) {
            const timestring = `${par.HH}${par.mm}`;
            return +timestring;
        },
    },
};
</script>

<style>
@import 'vue2-timepicker/dist/VueTimepicker.css';

.time-picker {
    width: 100%;
}

.time-picker input.display-time {
    width: 100%;
    height: 4rem;
    border-color: var(--color-onlight-border-darker);
    padding: 0 var(--padding-content-side);
}

.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
    background: var(--color-onlight-highlight);
}
</style>
