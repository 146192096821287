<template>
    <modal>
        <content v-on-clickaway="close" v-if="data">
            <Nav :header="true" :brand="false" :fixed="true">
                <template slot="left">
                    <li>
                        <div>{{ $i18n.t('temperatures.twohours_measurement') }}</div>
                    </li>
                </template>
                <template slot="nav_right">
                    <li>
                        <button type="button" class="button --neutral --icon" @click="close">
                            <icon class="mdi-undo-variant"></icon>
                        </button>
                    </li>
                </template>
            </Nav>

            <TaskRemarks v-bind="{ done: $parent.done, data, type: 'temperature-twohours' }" @change="onRemark" @show="show_remarks = true" @hide="show_remarks = false" />
            <div v-if="!show_remarks && !data.checked">
                <br />
                <form @submit.prevent="$nextTick(() => { save_measurement() })" @keydown.enter="enterClick" class="add-padding_side">
                    <fieldset>
                        <label for="temp_productname">{{ $i18n.t('temperatures.fields.product_name') }}</label>
                        <input type="text" id="temp_productname" v-model="data.component_name" required autofocus />
                    </fieldset>

                    <fieldset :disabled="first_input_disabled">
                        <label for="temp_preparationtime">{{ $i18n.t('temperatures.fields.time_removed_from_cooling') }}</label>
                        <TimePicker id="temp_preparationtime" v-model="data.preparation_time" :clear="false" :step="5"></TimePicker>
                    </fieldset>

                    <fieldset>
                        <label for="temp_cooltime">{{ $i18n.t('temperatures.fields.time_end') }}</label>
                        <TimePicker id="temp_cooltime" v-model="data.cool_time" :clear="false" :step="5"></TimePicker>
                    </fieldset>

                    <notice v-if="!time_ok" class="--bg-warning">
                        <div>{{ $store.state.selected_department.twohours_time_protocol }}</div>
                    </notice>

                    <fieldset v-if="!time_ok">
                        <label for="remark">{{ $i18n.t('tasks.remark') }}</label>
                        <textarea v-model="data.remark" required></textarea>
                    </fieldset>

                    <button class="button" type="button" @click="delayedSubmit">
                        {{ $i18n.t('actions.save') }}
                        <icon class="mdi-check"></icon>
                    </button>

                    <button class="button" type="submit" ref="submitbutton" hidden>real {{ $i18n.t('actions.save') }}</button>
                </form>
                <br />
            </div>
            <div class="info__list">
                <template v-if="data.checked">
                    <div class="add-padding_side" v-if="data.component_name">
                        <div class="--size6 --color-secondary">{{ $i18n.t('temperatures.fields.product_name') }}</div>
                        <div>{{ data.component_name }}</div>
                    </div>

                    <div class="add-padding_side" v-if="data.preparation_time">
                        <div class="--size6 --color-secondary">{{ $i18n.t('temperatures.fields.time_removed_from_cooling') }}</div>
                        <div>{{ convert_date(data.preparation_time, '', 'prettytime') }}</div>
                    </div>

                    <div class="add-padding_side" v-if="data.cool_time">
                        <div class="--size6 --color-secondary">{{ $i18n.t('temperatures.fields.time_end') }}</div>
                        <div>{{ convert_date(data.cool_time, '', 'prettytime') }}</div>
                    </div>
                </template>

                <div class="add-padding_side" v-if="data.notes && !show_remarks">
                    <div class="--size6 --color-secondary">{{ $i18n.t('tasks.instructions') }}</div>
                    <div v-html="data.notes"></div>
                </div>

                <TaskCredits v-bind="{ data, type: 'temperature-twohours' }" />
            </div>
        </content>
    </modal>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import { convert_date } from '@/assets/js/helpers';
import Nav from '@/components/Nav.vue';
import TimePicker from '@/components/forms/TimePicker.vue';
import TaskRemarks from '@/views/tasks/modals/TaskRemarks.vue';
import TaskCredits from '@/views/tasks/modals/TaskCredits.vue';

export default {
    name: 'TemperatureTwohoursTaskSpecs',
    components: {
        Nav,
        TimePicker,
        TaskRemarks,
        TaskCredits,
    },
    props: {
        data: Object,
    },
    data() {
        return {
            first_input_disabled: false,
            show_remarks: false,
        };
    },
    methods: {
        convert_date,

        close() {
            if (!this.$store.state.app.show_initials) {
                this.$emit('close');
            }
        },

        save_measurement() {
            if (!this.data.checked) {
                if (
                    this.data.preparation_time > 0
                    && this.data.cool_time > 0
                    && this.data.cool_time > 0
                ) {
                    this.data.checked = true;
                }
                this.$emit('change', { ids: [this.data.id] });
                this.close();
            }
        },

        get_current_time() {
            const date = new Date();
            const hours = date.getHours();
            const minutes = (`0${Math.round(date.getMinutes() / 5) * 5}`).substr(-2);
            return +(`${hours}${minutes}`);
        },
    },
    computed: {
        time_ok() {
            if ((typeof this.data.preparation_time === 'number' && this.data.preparation_time > 0)
                && (typeof this.data.cool_time === 'number' && this.data.cool_time > 0)) {
                return this.data.preparation_time <= this.data.cool_time && this.data.preparation_time + 200 > this.data.cool_time;
            }
            return true;
        },
    },
    mounted() {
        if (this.data) {
            if (!this.data.preparation_time) {
                this.$set(this.data, 'preparation_time', this.get_current_time());
            } else if (!this.data.cool_time) {
                this.$set(this.data, 'cool_time', this.get_current_time());
            }
            if (this.data.preparation_time > 0 && this.data.initials) {
                this.first_input_disabled = true;
            }
        }
    },
    directives: {
        onClickaway,
    },
};
</script>
<style>
.savenotice {
    display: inline-block;
    width: 60%;
    vertical-align: top;
    margin-left: 2rem;
}
</style>
