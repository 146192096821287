<template>
    <ul class="not-item">
        <li class="add-padding_side is-item" v-for="(meas, index) in measurements" :key="index">
            <div>
                {{ $i18n.t('temperatures.dishwasher-' + meas.name) }}:
                <span class="--color-secondary">{{ meas.temperature }} &deg;C</span>
                <div v-if="temp_range_error(meas.name, meas.temperature)" class="--size6 --color-error">{{
                    $i18n.t('temperatures.temperature_range_error', temp_range_error(meas.name, meas.temperature)) }}</div>
            </div>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'DishwasherMeasurements',
    props: {
        measurements: Array,
        data: Object,
        view: String,
    },
    methods: {
        temp_range_error(measurementName, temp) {
            let temps = this.$store.state.data.dishwasher_temps[measurementName];
            const inputTemp = parseFloat(temp);
            if (measurementName === 'main_wash' && this.measurements.includes('pre_wash')) {
                temps = this.$store.state.data.dishwasher_temps.main_wash_has_pre_wash;
            }

            if (typeof temps?.max !== 'number' || inputTemp > temps.max || inputTemp < temps.min) {
                return temps;
            }
            return false;
        },
    },
};
</script>
