<template>
    <component :is="component" :data="item" v-if="component" v-on="$listeners" v-bind="$attrs" />
</template>

<script>
export default {
    name: 'Listitem_loader',
    props: {
        item: Object,
        type: String,
    },
    data() {
        return {
            component: null,
        };
    },
    computed: {
        loader() {
            if (!this.type) { return null; }
            return () => import(`./listitems/${this.type}.vue`);
        },
    },
    mounted() {
        this.loader()
            .then(() => {
                this.component = () => this.loader();
            })
            .catch(() => {
                console.log('Error loading component', this.type);
            });
    },
};
</script>
