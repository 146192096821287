<template>
    <ul class="not-item">
        <li class="add-padding_side is-item" v-for="(meas, index) in measurements" :key="index">
            <div v-if="meas.name">
                {{ $i18n.t('temperatures.portioning_' + meas.name) }}:
                <span class="--color-secondary">{{ convert_date(meas.preparation_time, '', 'prettytime') }}. {{ meas.temperature }} &deg;C</span>
                <div
                    v-if="temp_range.max && parseFloat(meas.temperature) > parseFloat(temp_range.max)"
                    class="--size6 --color-error"
                >{{ $i18n.t('temperatures.temperature_max_error', { temp: temp_range.max + '&deg;C' }) }}</div>
                <div
                    v-if="temp_range.min && parseFloat(meas.temperature) < parseFloat(temp_range.min)"
                    class="--size6 --color-error"
                >{{ $i18n.t('temperatures.temperature_min_error', { temp: temp_range.min + '&deg;C' }) }}</div>
            </div>
        </li>
    </ul>
</template>
<script>
import { convert_date } from '@/assets/js/helpers';

export default {
    name: 'PortioningMeasurements',
    props: {
        measurements: Array,
        data: Object,
        view: String,
    },
    computed: {
        temp_range() {
            const cat = ['hot', 'cold'].includes(this.data.category) ? this.data.category : 'cold';
            return this.$store.state.data.portioning_temps[cat];
        },
    },
    methods: {
        convert_date,
    },
};
</script>
