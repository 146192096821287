<template>
    <modal>
        <content v-on-clickaway="close" v-if="data">
            <Nav :header="true" :brand="false" :fixed="true">
                <template slot="left">
                    <li>
                        <div>{{ data.name || '' }} {{ $i18n.t('temperatures.temperature_action') }}</div>
                    </li>
                </template>
                <template slot="nav_right">
                    <li>
                        <button type="button" class="button --neutral --icon" @click="close">
                            <icon class="mdi-undo-variant"></icon>
                        </button>
                    </li>
                </template>
            </Nav>

            <TaskRemarks v-bind="{ done: $parent.done, data, type: 'temperature-fryer' }" @change="onRemark" @show="show_remarks = true" @hide="show_remarks = false" />
            <div v-if="!show_remarks && !data.checked">
                <br />
                <form class="add-padding_side" @submit.prevent="$nextTick(() => { save_measurement() })" @keydown.enter="enterClick">
                    <fieldset>
                        <label for="temp_producttemp">{{ $i18n.t('temperatures.fields.temperature') }}</label>
                        <input type="number" inputmode="numeric" autocomplete="off" id="temp_producttemp" step="0.01" v-model="data.temperature" required @change="check_temp" />
                    </fieldset>
                    <notice v-if="temperature_ok === 'warning'" class="--bg-warning">
                        <div>{{ $i18n.t('temperatures.fryer_tempwarning_checkbox', { min: 175, max: 185 }) }}</div>
                    </notice>
                    <notice v-if="temperature_ok === false" class="--bg-error">
                        <div>
                            {{ $i18n.t('temperatures.fryer_tempwarning_error', { temp: temperatures.allowed }) }}.
                            <br />
                            {{ $store.state.selected_department.deepfry_temperature_protocol }}
                        </div>
                    </notice>

                    <fieldset>
                        <input type="checkbox" id="temp_visual" v-model="data.visual_check" />
                        <label for="temp_visual">{{ $i18n.t('temperatures.fields.visual_check') }}</label>
                        <br />
                    </fieldset>

                    <fieldset v-if="!temperature_ok || !data.visual_check">
                        <label for="remark">{{ $i18n.t('tasks.remark') }}</label>
                        <textarea v-model="data.remark" required></textarea>
                    </fieldset>

                    <button class="button" type="button" @click="delayedSubmit" ref="fakesubmitbutton">
                        {{ $i18n.t('actions.save') }}
                        <icon class="mdi-check"></icon>
                    </button>

                    <button class="button" type="submit" ref="submitbutton" hidden>real {{ $i18n.t('actions.save') }}</button>
                </form>
                <br />
            </div>

            <div class="info__list">
                <template v-if="data.checked">
                    <div class="add-padding_side" v-if="data.temperature">
                        <div class="--size6 --color-secondary">{{ $i18n.t('temperatures.fields.temperature') }}</div>
                        <div>{{ data.temperature }} &deg;C</div>
                    </div>

                    <notice v-if="temperature_ok === 'warning'" class="--bg-warning">
                        <div>{{ $i18n.t('temperatures.fryer_tempwarning_checkbox', { min: 175, max: 185 }) }}</div>
                    </notice>

                    <div class="add-padding_side">
                        <div class="--size6 --color-secondary">{{ $i18n.t('temperatures.fields.visual_check') }}</div>
                        <icon v-if="data.visual_check" class="mdi-check"></icon>
                        <icon v-else class="mdi-close-box --color-error"></icon>
                    </div>
                </template>

                <div class="add-padding_side" v-if="data.notes && !show_remarks">
                    <div class="--size6 --color-secondary">{{ $i18n.t('tasks.instructions') }}</div>
                    <div v-html="data.notes"></div>
                </div>

                <TaskCredits v-bind="{ data, type: 'temperature-fryer' }" />
            </div>
        </content>
    </modal>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import Nav from '@/components/Nav.vue';
import TaskRemarks from '@/views/tasks/modals/TaskRemarks.vue';
import TaskCredits from '@/views/tasks/modals/TaskCredits.vue';

export default {
    name: 'TemperatureFryerTaskSpecs',
    components: { Nav, TaskRemarks, TaskCredits },
    props: {
        data: Object,
    },
    data() {
        return {
            temperature_ok: true,
            show_remarks: false,
        };
    },
    computed: {
        temperatures() {
            return this.$store.getters.fryertemps;
        },

        department_type() {
            const selected = this.$store.state.selected_department;
            return selected.type || 'other';
        },
    },
    methods: {
        close() {
            if (!this.$store.state.app.show_initials) {
                this.$emit('close');
            }
        },

        check_temp() {
            const temp = parseFloat(this.data.temperature);
            if (temp) {
                if (temp <= this.temperatures.allowed) {
                    this.temperature_ok = true;
                } else if (temp > (this.department_type === 'healthcare' ? 175 : 185)) {
                    this.temperature_ok = false;
                } else if (this.department_type !== 'healthcare' && temp > 175) {
                    this.temperature_ok = 'warning';
                } else {
                    this.temperature_ok = false;
                }
            }
        },

        save_measurement() {
            if (!this.data.checked) {
                if (this.data.temperature) {
                    this.data.checked = true;
                    this.$emit('change', { ids: [this.data.id] });

                    if (this.temperature_ok === true) {
                        this.close();
                    } else {
                        this.$refs.fakesubmitbutton.classList.add('--loading');
                        setTimeout(() => {
                            this.close();
                        }, 5000);
                    }
                }
            }
        },
    },
    mounted() {
        if (this.data && this.data.temperature) {
            this.check_temp();
        }
    },
    directives: {
        onClickaway,
    },
};
</script>
