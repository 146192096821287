/**
 * capitalize_first
 * Capitalizes first letter of string
 */
export default function capitalize_first(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convert_date(timestamp, ymd, output, date, lang) {
    if (!date && output !== 'prettytime') {
        if (timestamp) {
            if (timestamp === 'now') {
                date = new Date();
            } else if (timestamp === 'tomorrow') {
                date = new Date(Date.now() + (24 * 60 * 60 * 1000));
            } else {
                date = new Date(timestamp * 1000);
            }
        } else if (ymd) {
            date = new Date(ymd);
        }
    }
    switch (output) {
    case 'Y-m-d':
        return `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).substr(-2)}-${(`0${date.getDate()}`).substr(-2)}`;

    case 'timestamp':
        date.setHours(0, 0, 0, 0);
        return Math.floor(date.getTime() / 1000);

    case 'time':
        return `${date.getHours()}${(`0${date.getMinutes()}`).substr(0, 2)}`;

    case 'locale-date':
        return date.toLocaleDateString(lang, { weekday: 'long', day: 'numeric', month: 'long' });

    case 'locale-date-short':
        return date.toLocaleDateString(lang, { day: 'numeric', month: 'short' });

    case 'locale-weekday':
        return date.toLocaleDateString(lang, { day: 'numeric', weekday: 'long' });

    case 'locale-datetime':
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;

    case 'taskcredits-long':
        return date.toLocaleDateString(lang, {
            day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit',
        });

    case 'taskcredits-short':
        return date.toLocaleDateString(lang, {
            day: 'numeric', month: '2-digit', hour: '2-digit', minute: '2-digit',
        });

    case 'time-today':
        if (Math.floor(Date.now() / 1000) - timestamp > 86400) {
            const month = `0${date.getMonth() + 1}`;
            const day = `0${date.getDate() + 1}`;
            return `${month.substr(-2)}-${day.substr(-2)}`;
        }
        return date.toLocaleTimeString(lang, { hour: 'numeric', minute: '2-digit' });

    case 'prettytime': {
        let time = timestamp.toString();
        const len = time.length;
        if (len > 2) { time = `${time.substring(0, len - 2)}:${time.substring(len - 2)}`; }
        return time;
    }

    default:
        return '';
    }
}

export { capitalize_first };
