<!-- LoadingFullScreen 29.11.2018 -->
<template>
    <transition name="fade">
        <div class="loadingFullScreen" v-if="data.show">
            <div v-if="!data.message" class="lfs_cont" :class="{ 'lfs__spinner': spinner }">
                <slot></slot>
            </div>
            <h3 v-if="data.title">{{ data.title }}</h3>
            <div v-if="data.message" class="msg">{{ data.message }}<br /><br /></div>
            <button type="button" v-if="data.message" class="button --icon" onclick="window.location.reload();"><icon class="mdi-refresh"></icon></button>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'LoadingFullScreen',
    props: {
        spinner: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        data() {
            return this.$store.state.app.loading_fullscreen;
        },
    },
};
</script>

<style>
    /* Custom */
    .loadingFullScreen {
        --borderWidth: 0.2rem;
        --borderColor: var(--color-onlight-border);
        --borderActiveColor: var(--color-onlight-highlight);
        --bgColor: var(--bg-highlight);
        --color: var(--color-ondark-primary);
        --spinnerSize: 8rem;
    }
    /* End-Custom */

    .loadingFullScreen {
        background: var(--bgColor);
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: 9999; top: 0; left: 0; height: 100vh; width: 100vw;
        align-items: center; justify-content: center;
        transition: opacity 0.4s ease;
        will-change: opacity;
        color: var(--color);
    }
        .loadingFullScreen h3 {
            color: var(--color);
        }
            .loadingFullScreen .msg { opacity: 0.8; margin-bottom: 2.4rem; }

        .lfs_cont {
            width: var(--spinnerSize);
            height: var(--spinnerSize);
            margin-bottom: 2rem;
            position: relative;
            padding: calc(var(--spinnerSize) * 0.15);
        }
        .lfs__spinner, .lfs__spinner::after {
            content: '';
            border-radius: 50%;
            border: 0.2rem solid var(--borderColor);
            border-width: var(--borderWidth);
        }
        .lfs__spinner::after {
            position: absolute;
            left: calc(-1 * var(--borderWidth));
            top: calc(-1 * var(--borderWidth));
            width: calc(var(--spinnerSize) - var(--borderWidth));
            height: calc(var(--spinnerSize) - var(--borderWidth));
            animation: rotate 0.8s linear infinite;
            margin-bottom: 0;
            border-width: var(--borderWidth) 0 0 0;
            border-color: var(--borderActiveColor);
        }
</style>
