<template>
    <modal>
        <content v-on-clickaway="close" v-if="data">
            <Nav :header="true" :brand="false" :fixed="true">
                <template slot="left">
                    <li>
                        <div>
                            <template v-if="data.name">{{ data.name }}:</template>
                            {{ $i18n.t('temperatures.' + type + '_measurement') }}
                        </div>
                    </li>
                </template>
                <template slot="nav_right">
                    <li>
                        <button type="button" class="button --neutral --icon" @click="close">
                            <icon class="mdi-undo-variant"></icon>
                        </button>
                    </li>
                </template>
            </Nav>

            <div v-if="!data.checked">
                <!-- form part -->
                <TaskRemarks v-bind="{ done: $parent.done, data, type: 'temperature-preparation' }" @change="onRemark"
                    @show="show_remarks = true" @hide="show_remarks = false" />
                <br />
                <div v-if="!show_remarks">
                    <ul class="choose_category add-padding_side">
                        <li v-for="(comp, index) in components" :key="index">
                            <button class="button"
                                :class="{ '--no-icon': !comp.icon, '--neutral': (!selected_comp || selected_comp.id !== comp.id) }"
                                type="button" @click="comp_select(comp)">
                                {{ $i18n.t('food_categories.' + comp.name) }}
                                <icon v-if="comp.icon" :class="'mdi-' + comp.icon"></icon>
                            </button>
                        </li>
                    </ul>

                    <br />
                    <form class="add-padding_side" @submit.prevent="$nextTick(() => { save_measurement() })"
                        @keydown.enter="enterClick">
                        <fieldset>
                            <label for="temp_productname">{{ $i18n.t('temperatures.fields.component_name') }}</label>
                            <input type="text" id="temp_productname" v-model="data.component_name" required />
                        </fieldset>

                        <template v-if="type === 'preparation' || (type === 'regeneration' && selected_comp)">
                            <fieldset v-if="type === 'regeneration'">
                                <label for="temp_regenerationtime">{{ $i18n.t('temperatures.fields.time_regeneration')
                                }}</label>
                                <TimePicker id="temp_regenerationtime" v-model="data.regeneration_time" :clear="false"
                                    :step="5"></TimePicker>

                                <small class="--color-secondary" v-if="!time_ok">
                                    <span class="tag --bg-error">
                                        <span class="mdi mdi-information-outline"></span> info
                                    </span>
                                    <br />
                                    {{ $i18n.t('temperatures.regeneration_within_time', {
                                        minutes: 60, temp:
                                            $store.state.data.component_temps.regeneration.hot
                                    }) }}
                                </small>
                                <br />
                            </fieldset>

                            <fieldset v-if="type !== 'regeneration'">
                                <label for="temp_producttemp">{{ $i18n.t('temperatures.fields.temperature_preparation')
                                }}</label>
                                <TemperatureInput id="temp_producttemp" v-model="data.preparation_temperature"
                                    :required="true" @input="check_temp" />
                            </fieldset>
                            <template v-else>
                                {{ $i18n.t('temperatures.fields.preparation_end') }}
                                <div class="input__group">
                                    <fieldset>
                                        <label for="temp_preparationtemp">{{ $i18n.t('temperatures.fields.temperature')
                                        }}</label>
                                        <TemperatureInput id="temp_preparationtemp" v-model="data.preparation_temperature"
                                            :required="!!data.regeneration_end" @input="check_temp" />
                                    </fieldset>

                                    <fieldset>
                                        <label for="temp_preparationtime">{{ $i18n.t('temperatures.fields.time') }}</label>
                                        <TimePicker id="temp_preparationtime" v-model="data.regeneration_end" :clear="false"
                                            :step="5"></TimePicker>
                                    </fieldset>
                                </div>
                            </template>

                            <fieldset v-if="$store.state.selected_department.does_distribute">
                                <label for="temp_disttemp">{{ $i18n.t('temperatures.fields.temperature_distribution')
                                }}</label>
                                <TemperatureInput id="temp_disttemp" v-model="data.distribution_temperature"
                                    :required="!!data.preparation_temperature" @input="check_dist_temp" />
                            </fieldset>
                        </template>

                        <notice v-if="!temperature_ok" class="--bg-warning">
                            <div>{{ $store.state.selected_department.preparation_temperature_protocol }}</div>
                        </notice>

                        <notice v-if="!time_ok && !data.checked" class="--bg-warning">
                            <div>{{ $store.state.selected_department.preparation_time_protocol }}</div>
                        </notice>

                        <notice v-if="!distribution_temperature_ok && !data.checked" class="--bg-warning">
                            <div>{{ $store.state.selected_department.distribution_temperature_protocol }}</div>
                        </notice>

                        <fieldset v-if="!distribution_temperature_ok || !temperature_ok || !time_ok">
                            <label for="remark">{{ $i18n.t('tasks.remark') }}</label>
                            <textarea v-model="data.remark" required></textarea>
                        </fieldset>

                        <button class="button" type="button" @click="delayedSubmit">
                            {{ $i18n.t('actions.save') }}
                            <icon class="mdi-check"></icon>
                        </button>

                        <button class="button" type="submit" ref="submitbutton" hidden>real {{ $i18n.t('actions.save')
                        }}</button>

                        <div v-if="type === 'regeneration' && selected_comp && selected_comp.type === 'hot'"
                            class="--color-secondary savenotice">&mdash; {{ $i18n.t('temperatures.regeneration_within_time',
                                { minutes: 60, temp: $store.state.data.component_temps.regeneration.hot }) }}</div>
                        <br />
                    </form>
                </div>
            </div>

            <div class="info__list">
                <div class="add-padding_side" v-if="data.notes">
                    <div class="--size6 --color-secondary">{{ $i18n.t('tasks.instructions') }}</div>
                    <div v-html="data.notes"></div>
                </div>

                <template v-if="data.checked">
                    <div class="add-padding_side" v-if="data.component_name">
                        <div class="--size6 --color-secondary">{{ $i18n.t('temperatures.fields.component_name') }}</div>
                        <div>
                            {{ data.component_name }}
                            <span class="tag --neutral" v-if="selected_comp && selected_comp.name">
                                <icon :class="['mdi-' + selected_comp.icon]" />
                                {{ $i18n.t('food_categories.' + selected_comp.name) }}
                            </span>
                        </div>
                    </div>

                    <div class="add-padding_side" v-if="data.preparation_temperature">
                        <div class="--size6 --color-secondary">{{ $i18n.t('temperatures.fields.temperature_preparation') }}
                        </div>
                        <div>{{ data.preparation_temperature }} &deg;C</div>
                    </div>

                    <div class="add-padding_side"
                        v-if="$store.state.selected_department.does_distribute && data.distribution_temperature">
                        <div class="--size6 --color-secondary">{{ $i18n.t('temperatures.fields.temperature_distribution') }}
                        </div>
                        <div>{{ data.distribution_temperature }} &deg;C</div>
                    </div>
                </template>

                <TaskCredits v-bind="{ data, type: 'temperature-preparation' }" />
            </div>
        </content>
    </modal>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import { convert_date } from '@/assets/js/helpers';
import Nav from '@/components/Nav.vue';
import TimePicker from '@/components/forms/TimePicker.vue';
import TaskRemarks from '@/views/tasks/modals/TaskRemarks.vue';
import TaskCredits from '@/views/tasks/modals/TaskCredits.vue';
import TemperatureInput from '@/components/forms/TemperatureInput.vue';

export default {
    name: 'TemperaturePreparationTaskSpecs',
    components: {
        Nav,
        TimePicker,
        TaskRemarks,
        TaskCredits,
        TemperatureInput,
    },
    props: {
        data: Object,
        type: String,
    },
    data() {
        return {
            measurement: {},
            selected_comp: null,
            temperature_ok: true,
            distribution_temperature_ok: true,
            show_remarks: false,
        };
    },
    methods: {
        convert_date,

        close() {
            if (!this.$store.state.app.show_initials) {
                this.$emit('close');
            }
        },

        comp_select(comp) {
            this.selected_comp = comp;
            this.data.component = comp.id;
            if (this.data.preparation_temperature) {
                this.check_temp();
            }
        },

        check_temp() {
            const temp = parseFloat(this.data.preparation_temperature);
            if (this.selected_comp && (this.selected_comp.type || this.selected_comp.subtype)) {
                if (this.selected_comp.subtype) {
                    this.temperature_ok = this.temperatures[this.selected_comp.subtype][this.selected_comp.type](temp);
                } else {
                    this.temperature_ok = this.temperatures[this.type][this.selected_comp.type](temp);
                }
            } else {
                this.temperature_ok = false;
            }
        },

        check_dist_temp() {
            if (this.selected_comp && (this.selected_comp.type || this.selected_comp.subtype)) {
                const temp = parseFloat(this.data.distribution_temperature);
                this.distribution_temperature_ok = this.temperatures.distribution[this.selected_comp.type](temp);
            }
        },

        save_measurement() {
            if (!this.data.checked && this.selected_comp && (this.selected_comp.type || this.selected_comp.subtype)) {
                if (this.type === 'regeneration' && this.selected_comp.type === 'hot') {
                    if (!this.data.regeneration_time) {
                        this.$set(this.data, 'regeneration_time', this.get_current_time());

                        this.$emit('change', { ids: [this.data.id] });
                        this.close();
                    } else if (!this.data.regeneration_end) {
                        this.$set(this.data, 'regeneration_end', this.get_current_time());
                        this.$emit('change', { ids: [this.data.id] });
                        this.close();
                    } else {
                        this.data.checked = true;
                        this.$emit('change', { ids: [this.data.id] });
                        this.close();
                    }
                } else {
                    this.data.checked = true;
                    this.$emit('change', { ids: [this.data.id] });
                    this.close();
                }
            }
            return '';
        },

        get_current_time() {
            const date = new Date();
            const hours = date.getHours();
            const minutes = (`0${Math.round(date.getMinutes() / 5) * 5}`).substr(-2);
            return +(`${hours}${minutes}`);
        },
    },
    computed: {
        components() {
            return this.$store.getters.components;
        },

        allowed_temperatures() {
            return this.$store.getters.preprationtemps;
        },

        time_ok() {
            if (this.type === 'regeneration' && this.selected_comp && this.selected_comp.type === 'hot') {
                if (this.data.regeneration_time && this.data.regeneration_end && (this.data.regeneration_time + 100 < this.data.regeneration_end)) {
                    return false;
                }
            }
            return true;
        },

        temperatures() {
            return {
                preparation: {
                    hot: (temp) => temp >= this.allowed_temperatures.preparation.hot,
                    cold: (temp) => temp <= this.allowed_temperatures.preparation.cold,
                },
                regeneration: {
                    hot: (temp) => temp >= this.allowed_temperatures.regeneration.hot,
                    cold: (temp) => temp <= this.allowed_temperatures.regeneration.cold,
                },
                distribution: {
                    hot: (temp) => temp >= this.allowed_temperatures.distribution.hot,
                    cold: (temp) => temp <= this.allowed_temperatures.distribution.cold,
                },
            };
        },
    },
    mounted() {
        if (this.data) {
            if (this.data.component) {
                this.selected_comp = this.components.find((it) => it.id === this.data.component);
            }

            if (this.type === 'regeneration') {
                if (!this.data.regeneration_time) {
                    this.$set(this.data, 'regeneration_time', this.get_current_time());
                } else if (!this.data.regeneration_end) {
                    this.$set(this.data, 'regeneration_end', this.get_current_time());
                }
            }

            if (this.data.preparation_temperature) {
                this.check_temp();
            }
            if (this.data.distribution_temperature) {
                this.check_dist_temp();
            }
        }
    },
    directives: {
        onClickaway,
    },
};
</script>
