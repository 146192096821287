<template>
    <modal>
        <content v-on-clickaway="close_click" v-if="data">
            <Nav :header="true" :brand="false" :fixed="true">
                <template slot="left">
                    <li>
                        <div>{{ data.name || $i18n.t('temperatures.custom_measurement') }}</div>
                    </li>
                </template>
                <template slot="nav_right">
                    <li><button type="button" class="button --neutral --icon" @click="close">
                            <icon class="mdi-undo-variant"></icon>
                        </button></li>
                </template>
            </Nav>

            <div v-if="!data.checked">
                <TaskRemarks v-bind="{ done: $parent.done, data, type: 'temperature-custom' }" @change="onRemark"
                    @show="show_remarks = true" @hide="show_remarks = false" />

                <div v-if="!show_remarks">
                    <form @submit.prevent="$nextTick(() => { save_measurement() })" class="add-padding_side"
                        @keydown.enter="enterClick">
                        <br />
                        <small class="--color-secondary">
                            <span class="tag --bg-info --color-primary"><span class="mdi mdi-information-outline"></span>
                                info</span><br />
                            {{ $i18n.t('departments.custom-task-vwa') }}
                        </small>
                        <br /><br />

                        <fieldset>
                            <label for="temp_productname">{{ $i18n.t('temperatures.fields.product_name') }}</label>
                            <input type="text" id="temp_productname" v-model="data.component_name" required autofocus />
                        </fieldset>

                        <fieldset>
                            <label for="temp_temperature">{{ $i18n.t('temperatures.fields.temperature') }}</label>
                            <TemperatureInput id="temp_temperature" v-model="data.temperature" @input="check_temp"
                                :required="true" />
                        </fieldset>

                        <notice v-if="temperature_ok === false" class="--bg-error">
                            <div>
                                {{ this.data.protocol_notice }}
                            </div>
                        </notice>

                        <fieldset v-if="!temperature_ok">
                            <label for="remark">{{ $i18n.t('tasks.remark') }}</label>
                            <textarea v-model="data.remark" required></textarea>
                        </fieldset>

                        <button class="button" type="button" @click="delayedSubmit">
                            {{ $i18n.t('actions.save') }}
                            <icon class="mdi-check"></icon>
                        </button>

                        <button class="button" type="submit" ref="submitbutton" hidden>
                            real {{ $i18n.t('actions.save') }}
                        </button>
                    </form>
                    <br />
                </div>
            </div>

            <div class="info__list">
                <template v-if="data.checked">
                    <div class="add-padding_side" v-if="data.component_name">
                        <div class="--size6 --color-secondary">{{ $i18n.t('temperatures.fields.product_name') }}</div>
                        <div>{{ data.component_name }}</div>
                    </div>

                    <div class="add-padding_side" v-if="data.temperature">
                        <div class="--size6 --color-secondary">{{ $i18n.t('temperatures.fields.temperature') }}</div>
                        <div>{{ data.temperature }} &deg;C</div>
                    </div>
                </template>

                <div class="add-padding_side" v-if="data.notes && !show_remarks">
                    <div class="--size6 --color-secondary">{{ $i18n.t('tasks.instructions') }}</div>
                    <div v-html="data.notes"></div>
                </div>

                <TaskCredits v-bind="{ data, type: 'temperature-custom' }" />
            </div>
        </content>
    </modal>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import Nav from '@/components/Nav.vue';
import TaskRemarks from '@/views/tasks/modals/TaskRemarks.vue';
import TaskCredits from '@/views/tasks/modals/TaskCredits.vue';
import TemperatureInput from '@/components/forms/TemperatureInput.vue';

export default {
    name: 'TemperatureCustomTaskSpecs',
    components: {
        Nav,
        TaskCredits,
        TaskRemarks,
        TemperatureInput,
    },
    props: {
        data: Object,
    },
    data() {
        return {
            temperature_ok: true,
            show_remarks: false,
        };
    },
    methods: {
        close() {
            if (!this.$store.state.app.show_initials) {
                this.$emit('close');
            }
        },

        close_click() {
            this.close('clickaway');
        },

        check_temp() {
            const temp = parseFloat(this.data.temperature);
            if (temp) {
                if (this.data.max_temperature && temp > parseFloat(this.data.max_temperature)) {
                    this.temperature_ok = false;
                } else if (this.data.min_temperature && temp < parseFloat(this.data.min_temperature)) {
                    this.temperature_ok = false;
                } else {
                    this.temperature_ok = true;
                }
            }
        },

        save_measurement() {
            if (!this.data.checked) {
                if (this.data.temperature) {
                    this.data.checked = true;
                    this.$emit('change', { ids: [this.data.id] });
                    if (this.data.checked) {
                        this.close();
                    }
                }
            }
        },
    },
    directives: {
        onClickaway,
    },
};
</script>
