import Vue from 'vue';
import Router from 'vue-router';
import Tasks from './views/Tasks.vue';
import TaskCleaning from './views/tasks/modals/Cleaning.vue';
import TaskTempCool from './views/tasks/modals/Temperature-cool.vue';
import TaskTempFryer from './views/tasks/modals/Temperature-fryer.vue';
import TaskTempPreparation from './views/tasks/modals/Temperature-preparation.vue';
import TaskTempReceive from './views/tasks/modals/Temperature-receive.vue';
import TaskTempStorage from './views/tasks/modals/Temperature-storage.vue';
import TaskTempDishwasher from './views/tasks/modals/Temperature-dishwasher.vue';
import TaskTempTwohours from './views/tasks/modals/Temperature-twohours.vue';
import TaskTempPortioning from './views/tasks/modals/Temperature-portioning.vue';
import TaskTempCustom from './views/tasks/modals/Temperature-custom.vue';
import i18n from './i18n';
import store from './store';

Vue.use(Router);

const propvalidator_reports = (route) => {
    const props = { ...route.params };
    if (props.timestamp) {
        props.timestamp = +props.timestamp;
    } else {
        props.timestamp = Math.floor(Date.now() / 1000);
    }
    return props;
};

const propvalidator_tasks = (route) => {
    const props = { ...route.params };
    if (props.timestamp) {
        props.timestamp = +props.timestamp;
    } else if (store.state.selected_department) {
        props.timestamp = store.state.selected_department.next_due_timestamp;
    }
    if (!props.timestamp) {
        props.timestamp = Math.floor(new Date().setHours(0, 0, 0, 0) / 1000);
    }
    if (props.id) {
        props.id = +props.id;
    }
    return props;
};

const create_router = () => new Router({
    mode: 'history',
    base: './',
    linkActiveClass: 'is-selected',
    routes: [
        {
            path: '/login',
            name: 'login',
            alias: '/logout',
            component: () => import('./views/Login.vue'),
            meta: {
                guest: true,
            },
        },
        {
            path: '/tasks/:timestamp?',
            name: 'tasks',
            component: Tasks,
            props(route) {
                return propvalidator_tasks(route);
            },
            children: [
                {
                    path: ':id/Cleaning',
                    name: 'TaskCleaning',
                    component: TaskCleaning,
                    props(route) {
                        return propvalidator_tasks(route);
                    },
                },
                {
                    path: ':id/Cool',
                    name: 'TaskTempCool',
                    component: TaskTempCool,
                    props(route) {
                        return propvalidator_tasks(route);
                    },
                },
                {
                    path: ':id/Fryer',
                    name: 'TaskTempFryer',
                    component: TaskTempFryer,
                    props(route) {
                        return propvalidator_tasks(route);
                    },
                },
                {
                    path: ':id/Preparation',
                    name: 'TaskTempPreparation',
                    component: TaskTempPreparation,
                    props(route) {
                        route.params.type = 'preparation';
                        return propvalidator_tasks(route);
                    },
                },
                {
                    path: ':id/Regeneration',
                    name: 'TaskTempRegeneration',
                    component: TaskTempPreparation,
                    props(route) {
                        route.params.type = 'regeneration';
                        return propvalidator_tasks(route);
                    },
                },
                {
                    path: ':id/Receive',
                    name: 'TaskTempReceive',
                    component: TaskTempReceive,
                    props(route) {
                        return propvalidator_tasks(route);
                    },
                },
                {
                    path: ':id/Storage',
                    name: 'TaskTempStorage',
                    component: TaskTempStorage,
                    props(route) {
                        return propvalidator_tasks(route);
                    },
                },
                {
                    path: ':id/Dishwasher',
                    name: 'TaskTempDishwasher',
                    component: TaskTempDishwasher,
                    props(route) {
                        return propvalidator_tasks(route);
                    },
                },
                {
                    path: ':id/Twohours',
                    name: 'TaskTempTwohours',
                    component: TaskTempTwohours,
                    props(route) {
                        return propvalidator_tasks(route);
                    },
                },
                {
                    path: ':id/Portioning',
                    name: 'TaskTempPortioning',
                    component: TaskTempPortioning,
                    props(route) {
                        return propvalidator_tasks(route);
                    },
                },
                {
                    path: ':id/Custom',
                    name: 'TaskTempCustom',
                    component: TaskTempCustom,
                    props(route) {
                        return propvalidator_tasks(route);
                    },
                },
            ],
        },
        {
            path: '/products',
            name: 'products',
            props: true,
            component: () => import('./views/Products.vue'),
            children: [
                {
                    path: ':id',
                    name: 'product',
                    component: () => import('./views/modals/Product.vue'),
                    props: true,
                },
            ],
        },
        {
            path: '/reports/print/',
            name: 'printreports',
            component: () => import('./views/reports/Print.vue'),
        },
        {
            path: '/reports/:timestamp?',
            alias: '/reports/dep/:id',
            name: 'reports',
            props(route) {
                return propvalidator_reports(route);
            },
            component: () => import('./views/Reports.vue'),
            meta: {
                button_print: true,
                button_add: false,
                type: 'reports',
                individual_path: '/tasks/'
            }
        },
        {
            path: '/dks/:id?',
            name: 'dks',
            component: () => import('./views/DKS.vue'),
            props(route) {
                return propvalidator_tasks(route);
            },
        },
        {
            path: '/dks-reports/:timestamp?',
            alias: '/dks-reports/dep/:id',
            name: 'dks-reports',
            props(route) {
                return propvalidator_reports(route);
            },
            component: () => import('./views/Reports.vue'),
            meta: {
                button_print: false,
                button_add: true,
                type: 'dks',
                individual_path: '/dks/'
            }
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('./views/Profile.vue'),
        },
        {
            path: '/Instructions-hygiene',
            name: 'instructions-hygiene',
            component: () => import('./views/Instructions-hygiene.vue'),
        },
    ],
});

const router = create_router();

const set_pagetitle = (to) => {
    let pagetitle = '';
    if (to.meta && to.meta.title) {
        pagetitle = to.meta.title;
        if (pagetitle.indexOf('%') > -1) {
            pagetitle = pagetitle.replace(/%(\w.+)%/g, (m, m1) => {
                const parts = m1.split('.');
                let val = to.params[parts[0]];
                if (val && parts[1] === 'translate') {
                    const translatepart = parts.slice(2, 4).join('.').replace('prop', val);
                    val = i18n.t(translatepart);
                }
                return val || '';
            });
        }
    }
    document.title = (pagetitle ? pagetitle + store.state.app.name_separator : '') + store.state.app.name;
};

router.beforeEach(async (to, from, next) => {
    if (store.state.app.nav_opened) {
        store.commit('nav', { action: 'close' });
    }
    if (!to.matched.length) { // Redirect home
        router.replace({ path: '/tasks' });
    } else if (to.path !== '/login' && !store.state.user.loggedin && !to.matched.every((page) => page.meta.guest)) { // Redirect to login
        router.replace({ path: '/login', query: { redirect: to.path } });
    } else {
        next();
        set_pagetitle(to);
    }
});

router.onError((e) => {
    console.log('error');
    router.replace({ path: '/tasks', params: { error: e } });
});

export default router;
