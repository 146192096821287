<template>
    <ul class="not-item">
        <li class="add-padding_side is-item" v-for="(meas, index) in measurements" :key="index" v-show="meas.category || meas.name">
            <template v-if="meas.category">{{ $i18n.t('food_categories.' + meas.category) }}:&nbsp;</template>
            <template v-if="meas.name">{{ meas.name || '' }}</template>&nbsp;
            <span class="--color-secondary" v-if="meas.temperature">{{ meas.temperature }} &deg;C</span>
            <br />
            <div
                v-if="meas.category && meas.temperature && !check_temp_in_measurement(meas.temperature, meas.category).status"
                class="--size6 --color-error"
            >{{ $i18n.t('temperatures.temperature_' + check_temp_in_measurement(meas.temperature, meas.category).type + '_error', { temp: check_temp_in_measurement(meas.temperature, meas.category).limit + '&deg;C' }) }}</div>
            <div v-if="meas.expired" class="--size6 --color-error">{{ $i18n.t('tasks.error') }}: {{ $i18n.t('temperatures.fields.freshness_title') }}</div>
            <div v-else class="--size6 --color-correct">{{ $i18n.t('temperatures.fields.freshness_title') }}</div>
            <template v-if="data.timestamp > 1640790918">
                <div v-if="!meas.hygiene_ok" class="--size6 --color-error">{{ $i18n.t('tasks.error') }}: {{ $i18n.t('temperatures.fields.hygiene_title') }}</div>
                <div v-else class="--size6 --color-correct">{{ $i18n.t('temperatures.fields.hygiene_title') }}</div>
            </template>
            <div v-if="!meas.visual_check" class="--size6 --color-error">{{ $i18n.t('tasks.error') }}: {{ $i18n.t('temperatures.fields.packaging_title') }}</div>
            <div v-else class="--size6 --color-correct">{{ $i18n.t('temperatures.fields.packaging_title') }}</div>
            <template v-if="data.timestamp > 1640790918">
                <div v-if="!meas.quantity_ok" class="--size6 --color-error">{{ $i18n.t('tasks.error') }}: {{ $i18n.t('temperatures.fields.quantity_title') }}</div>
                <div v-else class="--size6 --color-correct">{{ $i18n.t('temperatures.fields.quantity_title') }}</div>
            </template>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'ReceiveMeasurements',
    props: {
        data: Object,
        measurements: Array,
    },
    computed: {
        categories() {
            return this.$store.getters.categories;
        },
    },
    methods: {
        check_temp_in_measurement(temp, category) {
            const cat = this.categories.find((it) => it.id === category);
            if (cat) {
                if ('max_temperature' in cat) {
                    if (parseFloat(temp) > cat.max_temperature) {
                        if (cat.id === 'pastry' && parseFloat(temp) <= 20) {
                            return { status: true, limit: cat.max_temperature, type: 'max' };
                        }
                        return { status: false, limit: cat.max_temperature, type: 'max' };
                    }
                    return { status: true, limit: cat.max_temperature, type: 'max' };
                }
                if ('min_temperature' in cat) {
                    if (parseFloat(temp) <= cat.min_temperature) {
                        if (cat.id === 'pastry' && parseFloat(temp) <= 20) {
                            return { status: true, limit: cat.min_temperature, type: 'min' };
                        }
                        return { status: false, limit: cat.min_temperature, type: 'min' };
                    }
                    return { status: true, limit: cat.min_temperature, type: 'min' };
                }
            }
            return { status: false, limit: null };
        },
    },
};
</script>
