<template>
    <div id="app">
        <Nav
            v-if="$store.state.user.loggedin"
            :shadow="true"
            brand_img="/img/logo_white_icon.svg"
            brand_to="/tasks"
            :powered_by_img="powered_by_img"
            >
            <template slot="left">
                <li><router-link to="/tasks/">{{ $i18n.t('tasks.item_plural') }}</router-link></li>
                <!-- <li><router-link to="/temperature/">{{ $i18n.t('temperatures.item_plural') }}</router-link></li> -->
                <li><router-link to="/products/">{{ $i18n.t('products.item_plural') }}</router-link></li>
                <li v-if="department?.type === 'housekeeping'" class="hide-nav-large"><router-link to="/dks-reports/">{{ $i18n.t('dks.title_long') }}</router-link></li>
                <li v-if="department?.type === 'housekeeping'" class="hide-nav-small"><router-link to="/dks-reports/">{{ $i18n.t('dks.title') }}</router-link></li>
                <li><router-link to="/reports/">{{ $i18n.t('reports.item_plural') }}</router-link></li>
                <li><router-link to="/profile/">{{ $i18n.t('profile.item_single') }}</router-link></li>
                <li><router-link to="/Instructions-hygiene/">{{ $i18n.t('general.instructions') }}</router-link></li>
                <li><router-link to="/logout" class="--color-muted">{{ $i18n.t('login.actions.logout') }}</router-link></li>
            </template>

            <template slot="nav_right">
                <li v-if="$store.state.selected_department" class="nav__right" @click="$store.commit('pick_department', true)">
                    <tooltip>{{ $i18n.t('general.department_select') }}</tooltip>
                    <div class="min-w-1">
                        <div class="--color-ondark-primary ellipsis">{{ $store.state.selected_department.name }}</div>
                        <div class="--size7 ellipsis">{{ $store.state.user.data.name }} - {{ $store.state.selected_department.organization.name }}</div>
                    </div>
                </li>
                <li v-else class="--color-ondark-primary nav__right" @click="$store.commit('pick_department', true)">
                    {{ $i18n.t('general.department_select') }}
                </li>
            </template>
        </Nav>

        <main>
            <div v-if="expired_organisation" class="notice--bar --bg-error">
                {{ $i18n.t('errors.organization_expired') }}
            </div>
            <router-view/>
        </main>

        <LoadingFullScreen :spinner="false" v-if="$store.state.user.loggedin">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" fill="#fff" class="bubble__load">
                <path class="pop" d="M6.687 15.527l8.84-8.84L43.81 34.974l-8.838 8.84zM134.973 6.688l8.84 8.84-28.285 28.283-8.84-8.838zM143.81 134.973l-8.838 8.84-28.284-28.286 8.84-8.84zM15.528 143.812l-8.84-8.84 28.285-28.283 8.84 8.838z"/>
                <g class="bubble">
                    <path d="M75 15a60 60 0 1 1-60 60 60.07 60.07 0 0 1 60-60m0-15a75 75 0 1 0 75 75A75 75 0 0 0 75 0z"/>
                    <path d="M34.49 67.76a40.06 40.06 0 0 1 37.08-33.68l5.58-10C76.11 24 75.06 24 74 24a50 50 0 0 0-50 49.82z"/>
                </g>
            </svg>
        </LoadingFullScreen>

        <Toaster />

        <PickDepartment v-if="$store.state.user.loggedin && $store.state.app.pick_department" />
        <Changelog v-if="$store.state.app.changelog.show" />
    </div>
</template>

<script>
import PickDepartment from '@/views/modals/PickDepartment.vue';
import Nav from './components/Nav.vue';
import LoadingFullScreen from './components/LoadingFullScreen.vue';
import Toaster from './components/Toaster.vue';

export default {
    name: 'App',
    components: {
        Changelog: () => import('@/views/modals/Changelog.vue'),
        LoadingFullScreen,
        Nav,
        PickDepartment,
        Toaster,
    },

    computed: {
        department() {
            return this.$store.state.selected_department;
        },

        expired_organisation() {
            try {
                const enddate = this.department.organization.end_date;
                if (enddate > 7200) {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    today = Math.floor(today.getTime() / 1000);
                    if (enddate < today) {
                        return true;
                    }
                }
            } catch (err) {
                return false;
            }
            return false;
        },

        powered_by_img() {
            if ([95,96].includes(this.department?.organization?.id)) {
                return 'https://cdn.cleankit.nl/img/logo_christeyns_icon.png';
            }
            return undefined;
        },
    },
    watch: {
        department: {
            handler: 'change_department',
            immediate: true,
        },
    },

    methods: {
        change_department() {
            if (this.$router.currentRoute.fullPath.startsWith('/reports/dep/')) {
                const id = this.$router.currentRoute.fullPath.replace('/reports/dep/', '');
                this.$store.dispatch('select_department', { id: [parseInt(id, 10)] });
                this.$router.replace('/reports');
            }
            if (!this.$store.state.selected_department) {
                this.$store.commit('pick_department', true);
            }
        },
    },
};
</script>

<style>
    @import "assets/css/materialdesignicons.min.css";
    @import "assets/css/modules.css";
    @import "assets/css/style.css";
    @import "assets/css/app.css";
</style>
