<template>
    <toasts>
        <transition-group name="fade">
            <toast v-for="(toast, index) in toasts" :class="toast.classes" @click="click(toast)" :key="toast.msg.substr(0,15) + index">
                {{ toast.msg }}
                <span v-if="toast.html" v-html="toast.html"></span>
            </toast>
        </transition-group>
    </toasts>
</template>
<script>
export default {
    name: 'Toaster',
    computed: {
        toasts() {
            return this.$store.getters['Toaster/toasts'];
        },
    },
    methods: {
        click(item) {
            this.$store.commit('Toaster/remove', item.id);
        },
    },
};
</script>
<style>
    toasts {
        position: fixed; bottom: 1.6rem; left: 1.6rem;
        max-width: 90vw; z-index: 150;
    }
    toast {
        --toast-color: var(--color-ondark-primary);
        --toast-bg: rgb(var(--bg-dark));
        padding: 1.2rem 2rem;
        background-color: var(--toast-bg);
        color: var(--toast-color);
        display: block;
        float: left;
        clear: both;
        margin-top: 0.4rem;
        border-radius: var(--border-radius);
        cursor: pointer;
        transition: transform 0.2s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
        toast:hover { transform: scale(1.05); }

        toast.--neutral { --toast-color: var(--color-onlight-primary); --toast-bg: var(--bg-lightgrey); }
        toast.--highlight { --toast-color: var(--color-ondark-primary); --toast-bg: var(--color-onlight-highlight); }
        toast.--correct { --toast-color: var(--color-ondark-primary); --toast-bg: var(--bg-correct); }
        toast.--error { --toast-color: var(--color-ondark-primary); --toast-bg: var(--bg-error); }
        toast.--warning { --toast-color: var(--color-onlight-primary); --toast-bg: var(--bg-warning); }
        toast.--info { --toastlor: var(--color-ondark-primary); --toast-bg: var(--bg-info); }

        toast .button { margin: -0.6rem -1.4rem -0.6rem 0.8rem; }
</style>
