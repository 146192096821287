import Vue from 'vue';
import Vuex from 'vuex';
import { api } from '@/api';
import { setUser, captureException, getReplay } from '@sentry/vue';
import i18n from './i18n';
import apiErrors from './mixins/apiErrorhandling';
import router from '@/router';
import Errors from './stores/Errors';
import Toaster from './stores/Toaster';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        Errors,
        Toaster,
    },
    state: {
        app: {
            apikey: '975951c53d9b0c2422cdca7fe212284adce9a545153ce6a59d8d092f7465a6dfd1efc718dfbb7cfee86dd07ccf9912bb9468845691b910f02228600229d84b02',
            loading: false,
            loading_names: [],
            loading_fullscreen: {
                show: true,
                title: '',
                message: '',
            },
            name: 'Cleankit App',
            name_separator: ' - ',
            nav_opened: true,
            pick_department: true,
            show_initials: false,
            version: APPLICATION_VERSION,
            changelog: {
                show: false,
                data: [],
            },
        },
        api: {
            url: 'https://api.cleankit.nl/2.3/',
            headers: {
                Authorization: 'Bearer 975951c53d9b0c2422cdca7fe212284adce9a545153ce6a59d8d092f7465a6dfd1efc718dfbb7cfee86dd07ccf9912bb9468845691b910f02228600229d84b02',
                'app-version': APPLICATION_VERSION,
                lang: 'nl',
            },
        },
        selected_department: false,
        data: {
            allowed_cool_temps: {},
            categories: [
                {
                    id: 'meat',
                    icon: 'cow',
                },
                {
                    id: 'fish_fresh',
                    icon: 'fish',
                },
                {
                    id: 'fish_processed',
                    icon: 'fish',
                },
                {
                    id: 'shellfish',
                    icon: 'waves',
                },
                {
                    id: 'poultry',
                    icon: 'duck',
                },
                {
                    id: 'organ_meat',
                    icon: 'pig',
                },
                {
                    id: 'potatoes_panready',
                },
                {
                    id: 'vegetables_panready',
                    icon: 'carrot',
                },
                {
                    id: 'vegetables_dirty',
                    icon: 'food-apple',
                    temp_not_required: true,
                },
                {
                    id: 'dairy',
                    icon: 'food-variant',
                },
                {
                    id: 'freezer',
                    icon: 'snowflake',
                },
                {
                    id: 'pastry',
                    icon: 'cake',
                },
                {
                    id: 'bread',
                    icon: 'food-croissant',
                    temp_not_required: true,
                },
                {
                    id: 'dry_goods',
                    temp_not_required: true,
                },
                {
                    id: 'warm_product',
                },
            ],
            components: [
                {
                    id: 'soup',
                    name: 'soup_component',
                    type: 'hot',
                    icon: 'bowl',
                },
                {
                    id: 'starch',
                    name: 'starch_component',
                    type: 'hot',
                },
                {
                    id: 'meat',
                    name: 'meat_component',
                    type: 'hot',
                    icon: 'cow',
                },
                {
                    id: 'poultry',
                    name: 'poultry',
                    type: 'hot',
                    icon: 'duck',
                },
                {
                    id: 'fish',
                    name: 'fish',
                    type: 'hot',
                    icon: 'fish',
                },
                {
                    id: 'eggs',
                    name: 'eggs',
                    type: 'hot',
                },
                {
                    id: 'fried_heated',
                    name: 'fried_heated',
                    type: 'hot',
                    subtype: 'regeneration',
                },
                {
                    id: 'fried_raw',
                    name: 'fried_raw',
                    type: 'hot',
                },
                {
                    id: 'sauce',
                    name: 'sauce_component',
                    type: 'hot',
                },
                {
                    id: 'vegetables',
                    name: 'vegetables_component',
                    type: 'hot',
                    icon: 'carrot',
                },
                {
                    id: 'combined',
                    name: 'combined_component',
                    type: 'hot',
                },
                {
                    id: 'raw_vegetables',
                    name: 'raw_vegetables_component',
                    type: 'cold',
                    icon: 'food-apple',
                },
                {
                    id: 'dessert',
                    name: 'dessert_component',
                    type: 'cold',
                    icon: 'cake',
                },
                {
                    id: 'cold_sidedish',
                    name: 'cold_sidedish_component',
                    type: 'cold',
                },
            ],
            component_temps: {},
            fryer_temps: {},
            dishwasher_temps: {},
            portioning_temps: {},
            departments: {
                group: {
                    function: (item) => item.organization.name,
                    title: `${i18n.t('general.organization')}: %name%`,
                },
                items: [],
            },
            products: {
                products: [],
                incomplete_products: [],
            },
        },
        user: {
            loggedin: false,
            data: {},
        },
    },
    mutations: {
        changelog_show(state, r) {
            state.app.changelog.data = r;
            state.app.changelog.show = true;
        },

        changelog_hide(state) {
            state.app.changelog.show = false;
        },

        loadStart(state, name) {
            state.app.loading_names.push(name);
            state.app.loading = true;
        },

        loadEnd(state, name) {
            const index = state.app.loading_names.indexOf(name);
            if (index !== -1) {
                state.app.loading_names.splice(index, 1);
            }
            if (state.app.loading_names.length === 0) {
                state.app.loading = false;

                if (state.app.loading_fullscreen.show && !state.app.loading_fullscreen.message) {
                    state.app.loading_fullscreen.show = false;
                    state.app.loading_fullscreen.title = '';
                    state.app.loading_fullscreen.message = '';
                }
            }
        },

        update_login(state, r) {
            state.user.loggedin = true;
            state.api.headers.Authorization = `Bearer ${r.token}`;
            state.api.headers.lang = r.user.lang || 'nl';
        },

        logout(state) {
            localStorage.clear();
            state.user.loggedin = false;
            state.user.data = {};
            state.data.departments.items = [];
            state.selected_department = false;
            state.api.headers.Authorization = `Bearer ${state.app.apikey}`;
        },

        update_user(state, r) {
            if (r.lang && state.user.data.lang !== r.lang) {
                i18n.locale = r.lang;
            }
            state.user.data = r;
            const userdata = JSON.parse(localStorage.getItem('logindata'));
            if (userdata) {
                userdata.user = r;
                localStorage.setItem('logindata', JSON.stringify(userdata));
            }

            setUser({
                id: r.id.toString(),
                email: r.email,
                username: `${r.name} ${r.last_name}`,
                lang: r.lang,
            });

            if (r.email.endsWith('@adrz.nl')) {
                const replay = getReplay();
                if (replay) {
                    console.debug('start replay', replay);
                    replay.start();
                    replay.flush();
                }
            }
        },

        nav(state, data) {
            if (data.action === 'toggle') {
                state.app.nav_opened = !state.app.nav_opened;
            } else if (data.action === 'close') {
                state.app.nav_opened = false;
            }
        },

        show_initials(state, data) {
            state.app.show_initials = data;
        },

        store_departments(state, data) {
            const results = data.data.results.filter((it) => it.rooms_amount === 0);
            state.data.departments.items = results;
        },

        pick_department(state, show) {
            state.app.pick_department = show;
        },

        select_department(state, data) {
            state.selected_department = data;
        },

        store_department_products(state, data) {
            if (data.data.products) {
                state.data.products.products = data.data.products;
            }
            if (data.data.incomplete_products) {
                state.data.products.incomplete_products = data.data.incomplete_products;
            }
        },

        temp_restrictions(state, data) {
            if (data) {
                if (data.allowed_cool_temps) {
                    state.data.allowed_cool_temps = data.allowed_cool_temps;
                }
                if (data.portioning_temps) {
                    state.data.portioning_temps = data.portioning_temps;
                }
                if (data.dishwasher_temps) {
                    state.data.dishwasher_temps = data.dishwasher_temps;
                }
                if (data.categories) {
                    for (let i = 0, len = data.categories.length; i < len; i += 1) {
                        let cat = state.data.categories.find((it) => it.id === data.categories[i].id);
                        if (cat) {
                            cat = Object.assign(cat, data.categories[i]);
                        }
                    }
                }
                if (data.component_temps) {
                    state.data.component_temps = data.component_temps;
                }
                if (data.fryer_temps) {
                    state.data.fryer_temps = data.fryer_temps;
                }
            }
        },

        add_initials(state, val) {
            if (val && state.selected_department.last_initials.indexOf(val) === -1) {
                state.selected_department.last_initials.push(val);
                const amount = state.selected_department.last_initials.length;
                if (amount > 10) {
                    state.selected_department.last_initials = state.selected_department.last_initials.slice(amount - 10, 10);
                }
            }
        },
    },
    actions: {
        init_app({ dispatch }) {
            return new Promise((resolve) => {
                dispatch('get_config').then(() => {
                    resolve();
                }).catch((err) => {
                    console.log(err);
                    resolve();
                });
            });
        },

        get_config({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                try {
                    let logindata = localStorage.getItem('logindata');
                    if (logindata) {
                        logindata = JSON.parse(logindata);
                        if (logindata.token) {
                            commit('update_login', logindata);
                            dispatch('process_login', logindata);
                            resolve();
                        } else {
                            throw new Error('No token stored');
                        }
                    } else {
                        resolve();
                    }
                } catch (err) {
                    reject(err);
                }
            });
        },

        store_login({ commit, dispatch }, r) {
            return new Promise((resolve) => {
                commit('update_login', r);
                dispatch('process_login', r);
                localStorage.setItem('logindata', JSON.stringify(r));
                resolve();
            });
        },

        process_login({ commit, dispatch }, r) {
            if (r.user) {
                dispatch('get_departments', 'login');
                commit('update_user', r.user);
            }
        },

        get_departments({ commit, dispatch }, trigger = '') {
            api.get({
                name: 'get_departments',
                endpoint: 'departments/',
                data: { user: true },
                abort_prefer_running: true,
                loading: true,
            }).then((r) => {
                if (r.data && r.data.status === 'success') {
                    commit('store_departments', { data: r.data, trigger });

                    if (trigger === 'login') {
                        const prev_selected = localStorage.getItem('selected_department');
                        if (prev_selected) {
                            dispatch('select_department', { id: [+prev_selected] });
                        }
                    }

                    if (r.data.changelog && r.data.changelog.length) {
                        commit('changelog_show', r.data.changelog);
                    }
                }
            }).catch((err) => {
                apiErrors.methods.apiError(err);
            });
        },

        select_department({ commit, dispatch, state }, { id = [] }) {
            const find = state.data.departments.items.find((it) => id.includes(it.id));
            if (find) {
                commit('select_department', find);
                localStorage.setItem('selected_department', id[0]);
                commit('temp_restrictions', state.selected_department.temperature_restrictions);
                commit('pick_department', false);
                dispatch('get_department_products');
                if (router.currentRoute && !['tasks', '/tasks', '/tasks/'].includes(router.currentRoute.path) && router.currentRoute.path.includes('tasks')) {
                    router.push('/tasks').catch(() => { });
                }
            } else {
                commit('pick_department', true);
            }
        },

        get_department_products({ commit, state }) {
            api.get({
                name: 'get_departmentproducts',
                endpoint: 'products/',
                data: { department: state.selected_department.id },
            }).then((r) => {
                if (r.data && r.data.status === 'success') {
                    commit('store_department_products', { data: r.data.results });
                }
            }).catch((err) => {
                if (err && ['Timeout', 'Network error'].includes(err.message)) {
                    // do nothing
                } else {
                    if (import.meta.env.DEV) {
                        console.log('sentry exception', err, {
                            extra: {
                                section: 'get department products'
                            },
                        })
                    } else {
                        captureException(err, {
                            extra: {
                                section: 'get department products'
                            },
                        });
                    }
                }
            });
        },
    },
    getters: {
        apiConfig: (state) => state.api,
        categories: (state) => state.data.categories,
        components: (state) => state.data.components,
        products: (state) => state.data.products.products.concat(state.data.products.incomplete_products),
        fryertemps: (state) => state.data.fryer_temps,
        preprationtemps: (state) => state.data.component_temps,
        department: (state) => state.selected_department,
        user: (state) => state.user.data,
    },
});

export default store;