export default {
    namespaced: true,
    state: {
        timeout: 4000,
        toasts: [],
    },
    getters: {
        toasts: (state) => state.toasts,
    },
    mutations: {
        add(state, data) {
            const id = Math.floor((Math.random() * 1000) + 1);
            data.id = id;
            state.toasts.push(data);

            if (data.close !== 'click') {
                setTimeout(() => {
                    this.commit('Toaster/remove', data.id);
                }, state.timeout);
            }
        },

        remove(state, id) {
            const index = state.toasts.findIndex((it) => it.id === id);
            if (index > -1) {
                state.toasts.splice(index, 1);
            }
        },
    },
};
