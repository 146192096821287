<template>
    <div v-if="!done && !data.checked">
        <div class="task__actions --bg-neutral --text-center">
            <button type="button" @click="toggle('remark')" class="button --white --small" :class="{ '--highlight': show && !show_defect }">{{ $i18n.t('tasks.remark') }} <icon class="mdi-message-text" /></button>
            <button v-if="hasDefectOption" type="button" @click="toggle('defect')" class="button --white --small" :class="{ '--highlight': show && show_defect }">{{ $i18n.t('tasks.defect') }} <icon class="mdi-image-broken-variant" /></button>
        </div>

        <div v-if="show" class="info__list">
            <notice v-if="show_defect" class="--bg-info">
                <h4>{{ $i18n.t('tasks.protocol_defect') }}</h4>
                <div>
                    {{ $store.state.selected_department.instructions_for_defect }}
                </div>
            </notice>

            <form @submit.prevent="$nextTick(() => { $emit('change', { defect: show_defect }) })" class="add-padding_side">
                {{ $i18n.t('tasks.remark_info') }}
                <br /><br />
                <fieldset>
                    <label for="remark">{{ $i18n.t('tasks.remark') }}</label>
                    <textarea v-model="data.remark" :required="data.not_executed"></textarea>
                </fieldset>

                <fieldset>
                    <input type="checkbox" id="not_executed" v-model="data.not_executed" />
                    <label for="not_executed">
                        {{ $i18n.t('tasks.not_executed_task') }}
                    </label>
                    <br />
                </fieldset>

                <button type="submit" class="button">
                    <template v-if="show_defect">{{ $i18n.t('actions.save_and_send') }}</template>
                    <template v-else>{{ $i18n.t('actions.save') }}</template>
                    <icon class="mdi-check" />
                </button>

                <div v-if="show_defect" class="--color-secondary savenotice --size6">
                    &mdash; {{ $i18n.t('tasks.notice_to_tech_staff') }}
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TaskRemarks',
    props: {
        done: Boolean,
        data: Object,
        type: String,
    },

    data() {
        return {
            show: false,
            show_defect: false,
        };
    },

    methods: {
        toggle(type) {
            if (!this.show) {
                this.show = true;
                this.show_defect = (type === 'defect');
            } else if (type === 'defect' && !this.show_defect) {
                this.show_defect = true;
            } else if (type === 'remark' && this.show_defect) {
                this.show_defect = false;
            } else {
                this.show = false;
                this.show_defect = false;
            }

            if (this.show) {
                this.$emit('show');
            } else {
                this.$emit('hide');
            }
        },
    },

    computed: {
        hasDefectOption() {
            return !this.type.startsWith('temperature-');
        },
    },
};
</script>
