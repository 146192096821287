<template>
    <modal>
        <content v-on-clickaway="close" v-if="data">
            <Nav :header="true" :brand="false" :fixed="true">
                <template slot="left">
                    <li>
                        <div>
                            <template v-if="data.name">{{ data.name }}:</template>
                            {{ $i18n.t('temperatures.portioning_measurement') }}
                        </div>
                    </li>
                </template>
                <template slot="nav_right">
                    <li>
                        <button type="button" class="button --neutral --icon" @click="close">
                            <icon class="mdi-undo-variant"></icon>
                        </button>
                    </li>
                </template>
            </Nav>

            <TaskRemarks v-bind="{ done: $parent.done, data, type: 'temperature-portioning' }" @change="onRemark"
                @show="show_remarks = true" @hide="show_remarks = false" />
            <div v-if="!show_remarks && !data.checked">
                <br />
                <form @submit.prevent="$nextTick(() => { save_measurement() })" @keydown.enter="enterClick"
                    class="add-padding_side">
                    <template v-if="measurement && measurement.name">
                        <fieldset :disabled="measurement.name !== 'start'">
                            <label for="temp_productname">{{ $i18n.t('temperatures.fields.product_name') }}</label>
                            <input type="text" id="temp_productname" v-model="data.component_name" required autofocus />
                        </fieldset>
                        {{ $i18n.t('temperatures.portioning_' + measurement.name) }}
                        <div class="input__group">
                            <fieldset>
                                <label for="temp_preparationtemp">{{ $i18n.t('temperatures.fields.temperature') }}</label>
                                <TemperatureInput id="temp_preparationtemp" v-model="measurement.temperature"
                                    :required="true" @input="check_temp" />
                            </fieldset>

                            <fieldset>
                                <label for="temp_preparationtime">{{ $i18n.t('temperatures.fields.time') }}</label>
                                <TimePicker id="temp_preparationtime" v-model="measurement.preparation_time" :clear="false"
                                    :step="5" required></TimePicker>
                            </fieldset>
                        </div>
                    </template>

                    <notice v-if="!measurement.temperature_ok" class="--bg-warning">
                        <div>{{ $store.state.selected_department.portioning_temperature_protocol }}</div>
                    </notice>

                    <fieldset v-if="!measurement.temperature_ok">
                        <label for="remark">{{ $i18n.t('tasks.remark') }}</label>
                        <textarea v-model="data.remark" required></textarea>
                    </fieldset>

                    <button class="button" type="button" @click="delayedSubmit">
                        {{ $i18n.t('actions.save') }}
                        <icon class="mdi-check"></icon>
                    </button>

                    <button class="button" type="submit" ref="submitbutton" hidden>real {{ $i18n.t('actions.save')
                    }}</button>
                </form>
                <br />
            </div>
            <div class="info__list">
                <div class="add-padding_side" v-if="data.notes && !show_remarks">
                    <div class="--size6 --color-secondary">{{ $i18n.t('tasks.instructions') }}</div>
                    <div v-html="data.notes"></div>
                </div>
            </div>
            <div v-if="(data.measurements.length > 0 || data.checked) && !show_remarks"
                class="info__list list__measurements">
                <div class="add-padding_side not-item">
                    <h5 class="--color-highlight">
                        <br />
                        {{ $i18n.t('temperatures.measurements') }}
                    </h5>
                </div>

                <div class="add-padding_side" v-if="data.component_name">
                    <div class="--size6 --color-secondary">{{ $i18n.t('temperatures.fields.product_name') }}</div>
                    <div>{{ data.component_name }}</div>
                </div>

                <PortioningMeasurements :measurements="data.measurements" :data="data" />

                <TaskCredits v-bind="{ data, type: 'temperature-portioning' }" />
            </div>
        </content>
    </modal>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import { convert_date } from '@/assets/js/helpers';
import Nav from '@/components/Nav.vue';
import TimePicker from '@/components/forms/TimePicker.vue';
import TaskRemarks from '@/views/tasks/modals/TaskRemarks.vue';
import PortioningMeasurements from '@/views/tasks/measurements/Temperature-portioning.vue';
import TaskCredits from '@/views/tasks/modals/TaskCredits.vue';
import TemperatureInput from '@/components/forms/TemperatureInput.vue';

export default {
    name: 'TemperaturePortionTaskSpecs',
    components: {
        Nav,
        TimePicker,
        TaskRemarks,
        TaskCredits,
        PortioningMeasurements,
        TemperatureInput,
    },
    props: {
        data: Object,
    },
    data() {
        return {
            measurements: ['start', 'middle', 'end'],
            measurement: {},
            temp_range: {},
            show_remarks: false,
        };
    },
    methods: {
        convert_date,

        close() {
            if (!this.$store.state.app.show_initials) {
                this.$emit('close');
            }
        },

        check_temp() {
            if (typeof this.measurement.temperature === 'string' && this.measurement.temperature.includes(',')) {
                this.measurement.temperature = +this.measurement.temperature.replace(',', '.');
            }
            const this_measurement_temp = parseFloat(this.measurement.temperature);
            if (
                (this.temp_range.max && this_measurement_temp > this.temp_range.max)
                || (this.temp_range.min && this_measurement_temp < this.temp_range.min)
            ) {
                this.measurement.temperature_ok = false;
            } else {
                this.measurement.temperature_ok = true;
            }
        },

        measurement_data() {
            const existingAmountOfMeasurements = Array.isArray(this.data.measurements) ? this.data.measurements.length : 0;
            return {
                name: this.measurements[existingAmountOfMeasurements],
                temperature: null,
                preparation_time: this.get_current_time(),
                temperature_ok: true,
            };
        },

        new_measurement() {
            this.measurement = this.measurement_data();
        },

        save_measurement() {
            if (!this.data.checked) {
                this.data.measurements.push(JSON.parse(JSON.stringify(this.measurement)));
                this.new_measurement();

                if (this.data.measurements.length === this.measurements.length) {
                    this.data.checked = true;
                }
                this.$emit('change', { ids: [this.data.id] });
                if (this.data.checked) {
                    this.close();
                }
            }
        },

        get_current_time() {
            const date = new Date();
            const hours = date.getHours();
            const minutes = (`0${Math.round(date.getMinutes() / 5) * 5}`).substr(-2);
            return +(`${hours}${minutes}`);
        },
    },
    mounted() {
        if (this.data) {
            const portioning_temps = this.$store.state.data.portioning_temps;
            this.temp_range = portioning_temps[this.data.category];

            if (!this.data.middle_measurement) {
                this.measurements = ['start', 'end'];
            }
            this.new_measurement();
        }
    },
    directives: {
        onClickaway,
    },
};
</script>
