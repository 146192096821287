<template>
    <modal>
        <content v-on-clickaway="close_click" v-if="data">
            <Nav :header="true" :brand="false" :fixed="true">
                <template slot="left">
                    <li>
                        <div>{{ data.name || '' }} {{ $i18n.t('temperatures.temperature_action') }}</div>
                    </li>
                </template>
                <template slot="nav_right">
                    <li>
                        <button type="button" class="button --neutral --icon" @click="close">
                            <icon class="mdi-undo-variant"></icon>
                        </button>
                    </li>
                </template>
            </Nav>

            <div v-if="!data.checked">
                <TaskRemarks v-bind="{ done: $parent.done, data, type: 'temperature-storage' }" @change="onRemark"
                    @show="show_remarks = true" @hide="show_remarks = false" />

                <div v-if="!show_remarks">
                    <notice class="--bg-info"
                        v-if="remaining_products && (data.max_temperature && data.measurements.length > 0 || !data.max_temperature)">
                        <div>{{ $tc('temperatures.products_remaining', remaining_products) }}</div>
                    </notice>

                    <form @submit.prevent="$nextTick(() => { save_measurement() })" class="add-padding_side"
                        @keydown.enter="enterClick">
                        <br />
                        <template v-if="data.measurements.length === 0">
                            <div v-if="data.object_type === 'cooler'">
                                <small class="--color-secondary">
                                    <span class="tag --bg-info --color-primary">
                                        <span class="mdi mdi-information-outline"></span> info
                                    </span>
                                    <br />
                                    <div>{{ $i18n.t('temperatures.tips.cooler_settings', { temp: data.max_temperature }) }}
                                    </div>
                                </small>
                                <br />
                                <br />
                            </div>

                            <fieldset>
                                <label for="temp_productname">{{ $i18n.t('temperatures.fields.product_name') }}</label>
                                <input type="text" id="temp_productname" autofocus v-model="measurement.name" required />
                            </fieldset>

                            <fieldset>
                                <label for="temp_producttemp">{{ $i18n.t('temperatures.fields.temperature') }}</label>
                                <TemperatureInput id="temp_producttemp" v-model="measurement.temperature" :required="true"
                                    @input="check_temp" />
                            </fieldset>

                            <fieldset>
                                <input type="checkbox" id="temp_expirationdate" v-model="data.expired" />
                                <label for="temp_expirationdate">{{
                                    $i18n.t('temperatures.fields.expired_discovered')
                                }}</label>
                                <br />
                            </fieldset>
                        </template>
                        <template v-else>
                            <h5 class="--color-highlight">{{ $i18n.t('temperatures.new_measurement_product') }}</h5>

                            <fieldset>
                                <label for="temp_productname">{{ $i18n.t('temperatures.fields.product_name') }}</label>
                                <input type="text" id="temp_productname" v-model="measurement.name" required autofocus />
                            </fieldset>

                            <fieldset>
                                <label for="temp_producttemp">{{ $i18n.t('temperatures.fields.temperature') }}</label>
                                <TemperatureInput id="temp_producttemp" v-model="measurement.temperature" :required="true"
                                    @input="check_temp" />
                            </fieldset>
                            <div v-if="data.object_type === 'freezer'">
                                <small class="--color-secondary">
                                    <span class="tag --bg-info --color-primary">
                                        <span class="mdi mdi-information-outline"></span> info
                                    </span>
                                    <br />
                                    {{ $i18n.t('temperatures.tips.freezer_measurement') }}
                                </small>
                                <br />
                                <br />
                            </div>
                        </template>

                        <notice v-if="!measurement.temperature_ok || data.expired" class="--bg-warning">
                            <div>{{ $store.state.selected_department.storage_temperature_protocol }}</div>
                        </notice>

                        <fieldset v-if="!measurement.temperature_ok || data.expired">
                            <label for="remark">{{ $i18n.t('tasks.remark') }}</label>
                            <textarea v-model="data.remark" required></textarea>
                        </fieldset>

                        <button class="button" type="button" @click="delayedSubmit">
                            {{ $i18n.t('actions.save') }}
                            <icon class="mdi-check"></icon>
                        </button>

                        <button class="button" type="submit" ref="submitbutton" hidden>real {{
                            $i18n.t('actions.save')
                        }}</button>
                    </form>
                    <br />
                    <div class="add-padding_side" v-if="data.notes">
                        <br />
                        <div class="--size6 --color-secondary">{{ $i18n.t('tasks.instructions') }}</div>
                        <div v-html="data.notes"></div>
                    </div>
                </div>
            </div>

            <div v-if="(measurements.length > 0 || data.checked) && !show_remarks"
                class="info__list list__measurements">
                <div class="add-padding_side not-item">
                    <h5 class="--color-highlight">
                        <br />
                        {{ $i18n.t('temperatures.measurements') }}
                    </h5>
                </div>

                <StorageMeasurements :measurements="measurements" :data="data"></StorageMeasurements>

                <TaskCredits v-bind="{ data, type: 'temperature-storage' }" />
            </div>
        </content>
    </modal>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import Nav from '@/components/Nav.vue';
import StorageMeasurements from '@/views/tasks/measurements/Temperature-storage.vue';
import TaskRemarks from '@/views/tasks/modals/TaskRemarks.vue';
import TaskCredits from '@/views/tasks/modals/TaskCredits.vue';
import TemperatureInput from '@/components/forms/TemperatureInput.vue';

export default {
    name: 'TemperatureStorageTaskSpecs',
    components: {
        Nav,
        StorageMeasurements,
        TaskCredits,
        TaskRemarks,
        TemperatureInput,
    },
    props: {
        data: Object,
    },
    data() {
        return {
            measurement: {},
            show_remarks: false,
        };
    },
    methods: {
        close() {
            if (!this.$store.state.app.show_initials) {
                this.$emit('close');
            }
        },

        close_click() {
            this.close('clickaway');
        },

        check_temp() {
            if (typeof this.measurement.temperature === 'string' && this.measurement.temperature.includes(',')) {
                this.measurement.temperature = +this.measurement.temperature.replace(',', '.');
            }
            if (this.data.max_temperature && parseFloat(this.measurement.temperature) > parseFloat(this.data.max_temperature)) {
                this.measurement.temperature_ok = false;
            } else {
                this.measurement.temperature_ok = true;
            }
        },

        measurement_data() {
            return {
                name: '',
                temperature: null,
                temperature_ok: true,
            };
        },

        new_measurement() {
            this.measurement = this.measurement_data();
        },

        save_measurement() {
            if (!this.data.checked) {
                this.data.measurements.push(JSON.parse(JSON.stringify(this.measurement)));
                this.new_measurement();

                if (this.remaining_products < 1) {
                    this.data.checked = true;
                }
                this.$emit('change', { ids: [this.data.id] });
                if (this.data.checked) {
                    this.close();
                }
            }
        },
    },
    mounted() {
        try {
            if (this.data.max_temperature === false) {
                this.data.measurements.push({});
            }
        } catch (err) {
            console.log(err);
        }
        this.new_measurement();
    },
    computed: {
        remaining_products() {
            let remaining = 0;
            if (this.data.min_products_amount && this.data.min_products_amount > 0) {
                remaining = this.data.min_products_amount - this.data.measurements.length;
            }
            return Math.max(remaining, 0);
        },

        measurements() {
            const m = this.data.measurements;
            return m.filter((it) => {
                const remark = it.remark;
                return (!remark || typeof remark !== 'string' || !remark.startsWith('t:'));
            });
        }
    },
    directives: {
        onClickaway,
    },
};
</script>
