<template>
    <ul class="not-item">
        <li class="add-padding_side is-item" v-for="(meas, index) in measurements" :key="index">
            <div v-if="!meas.name && data.max_temperature && index === 0">
                <span v-if="view !== 'taskslist'" class="tag">{{ $i18n.t('departments.object') }}</span>
                {{ data.name }}:
                <span class="--color-secondary">{{ meas.temperature }} &deg;C</span>
                <div
                    v-if="parseFloat(meas.temperature) > parseFloat(data.max_temperature)"
                    class="--size6 --color-error"
                >{{ $i18n.t('temperatures.temperature_max_error', { temp: data.max_temperature + '&deg;C' }) }}</div>
            </div>
            <div v-else-if="data.max_temperature === false && index === 0"></div>
            <div v-else>
                {{ meas.name }}:
                <span class="--color-secondary">{{ meas.temperature }} &deg;C</span>
                <div
                    v-if="parseFloat(meas.temperature) > parseFloat(data.max_temperature)"
                    class="--size6 --color-error"
                >{{ $i18n.t('temperatures.temperature_max_error', { temp: data.max_temperature + '&deg;C' }) }}</div>
            </div>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'StorageMeasurements',
    props: {
        measurements: Array,
        data: Object,
        view: String,
    },
    computed: {
        categories() {
            return this.$store.getters.categories;
        },
    },
    methods: {
        check_temp_in_measurement(temp, category) {
            const cat = this.categories.find((it) => it.id === category);
            if (cat) {
                if (parseFloat(temp) > cat.max_temperature) {
                    return { status: false, max: cat.max_temperature };
                }
                return { status: true, max: cat.max_temperature };
            }
            return { status: false, max: null };
        },
    },
};
</script>
