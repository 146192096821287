<template>
    <div class="taken-group" v-if="typeof tasks === 'object' && (tasks.length || name === 'today' && no_periodical)">
        <div class="--color-secondary">
            {{ $i18n.t(`tasks.${name}`) }}
        </div>
        <list>
            <item v-if="name === 'today' && tasks.length === 0">
                <Empty :title="$i18n.t('tasks.empty_title')" :text="$i18n.t('tasks.empty_text')"></Empty>
            </item>

            <template v-for="(taskgroup, name) in grouped_tasks">

                <div v-if="Object.keys(grouped_tasks).length > 1" class="taskgroup__title --color-highlight"
                    :key="'group_' + name">
                    <div v-if="name === 'group_cleaning'">
                        <icon class="mdi-washing-machine"></icon> {{ $i18n.t(`temperatures.group_${name}`) }}
                    </div>
                    <div v-else-if="name === 'group_temperature'">
                        <icon class="mdi-oil-temperature"></icon> {{ $i18n.t(`temperatures.group_${name}`) }}
                    </div>
                </div>
                <component v-for="task in taskgroup" :is="'Task_'+task.type.replace('-','_')" :data="task" :key="task.id"
                    :group="name" :selected_id="selected_id" :done="done" @open="$emit('open', $event)"
                    @changed="single_changed">
                </component>

                <item v-if="name === 'group_cleaning' && showCheckAllDaily" class="--bg-correct item__flex"
                    @click="all_done" :key="'mark_all_done' + name">
                    <div class="item__checkbox">
                        <fieldset>
                            <input type="checkbox" />
                            <label></label>
                        </fieldset>
                    </div>
                    <div class="item__left">
                        <h2 class="--color-ondark-primary">{{ $i18n.t('tasks.done_daily') }}</h2>
                    </div>
                </item>
            </template>

        </list>
    </div>
</template>
<script>
import Empty from '@/components/Empty.vue';

export default {
    name: 'TaskGroup',
    components: {
        Empty,
        Task_Cleaning: () => import('@/views/tasks/types/Cleaning.vue'),
        Task_Temperature_cool: () => import('@/views/tasks/types/Temperature-cool.vue'),
        Task_Temperature_fryer: () => import('@/views/tasks/types/Temperature-fryer.vue'),
        Task_Temperature_preparation: () => import('@/views/tasks/types/Temperature-preparation.vue'),
        Task_Temperature_regeneration: () => import('@/views/tasks/types/Temperature-regeneration.vue'),
        Task_Temperature_receive: () => import('@/views/tasks/types/Temperature-receive.vue'),
        Task_Temperature_storage: () => import('@/views/tasks/types/Temperature-storage.vue'),
        Task_Temperature_portioning: () => import('@/views/tasks/types/Temperature-portioning.vue'),
        Task_Temperature_dishwasher: () => import('@/views/tasks/types/Temperature-dishwasher.vue'),
        Task_Temperature_twohours: () => import('@/views/tasks/types/Temperature-twohours.vue'),
        Task_Temperature_custom: () => import('@/views/tasks/types/Temperature-custom.vue'),
    },
    props: {
        done: Boolean,
        name: String,
        no_periodical: Boolean,
        tasks: [Array, Number],
        selected_id: Number,
    },
    methods: {
        all_done() {
            const ids = [];
            for (let i = 0, len = this.tasks.length; i < len; i += 1) {
                if (this.tasks[i].type === 'Cleaning' && this.tasks[i].task_interval === 86400) {
                    ids.push(this.tasks[i].id);
                }
            }

            this.$emit('changed', { ids, action: 'check' });
        },

        single_changed(payload) {
            this.$emit('changed', payload);
        },
    },
    computed: {
        showCheckAllDaily() {
            if (
                this.name === 'today'
                && this.$store.state.selected_department.check_day_tasks_in_one_go
                && this.tasks.length > 1
                && this.tasks.some((task) => task.type === 'Cleaning' && task.task_interval === 86400)) {
                return true;
            }
            return false;
        },

        grouped_tasks() {
            const groups = {};
            for (let i = 0, len = this.tasks.length; i < len; i += 1) {
                if (this.tasks[i].type === 'Cleaning') {
                    if (typeof groups.group_cleaning === 'undefined') { groups.group_cleaning = []; }
                    groups.group_cleaning.push(this.tasks[i]);
                } else {
                    if (typeof groups.group_temperature === 'undefined') { groups.group_temperature = []; }
                    groups.group_temperature.push(this.tasks[i]);
                }
            }

            Object.keys(groups).forEach((group) => {
                groups[group].sort((a, b) => ((a.task_interval === b.task_interval) ? 0 : a.task_interval - b.task_interval));
            });
            return groups;
        },
    },
};
</script>
<style>
.taskgroup__title {
    margin: 0.8rem 0 0.4rem 1.6rem;
    display: block;
}
</style>
